<template>
	<router-view></router-view>
</template>

<script>

export default {
  name: 'App',
	mounted() {
		const cookiebotScript = document.createElement('script');
		cookiebotScript.id = 'Cookiebot';
		cookiebotScript.src = 'https://consent.cookiebot.com/uc.js';
		cookiebotScript.setAttribute('data-cbid', '8677c0dd-701b-428e-9d30-fc7058f22987');
		cookiebotScript.setAttribute('data-blockingmode', 'auto');
		cookiebotScript.type = 'text/javascript';
		document.body.appendChild(cookiebotScript);

		// Google Analytics Tag
		const gtagScript = document.createElement('script');
		gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-N4FDLDFG9H';
		gtagScript.async = true;
		document.head.appendChild(gtagScript);

		const gtagInlineScript = document.createElement('script');
		gtagInlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-N4FDLDFG9H');
    `;
		document.head.appendChild(gtagInlineScript);
	},
}
</script>

