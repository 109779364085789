<template>
	<nav id="navbarTop" class="navbar navbar-expand-md navbar-custom animation-scroll">
		<div class="container">
			<!--		<a class="navbar-brand " href="/" title="WEWELCOM | Tecnología de Bienvenida">-->
			<!--			<img class="logo" style="background-image: url('https://devdemo.wewelcom.io/world/img/wewelcom/logo/logo.png')" src="" alt="">-->
			<!--		</a>-->
			<a href="https://wewelcom.io/" target="_blank">
				<img src="/world/img/wewelcom/logo/logo.png" alt="" class="logo">
			</a>
		</div>
	</nav>
</template>
<script>

export default {
	name: "NavbarWewelcom",
	data() {
		return {

		};
	},
	inject: {
	},
	computed: {
	},
	components: {
	},
	beforeMount() {
	},
	mounted() {
	},
	watch: {
	},
	methods: {
	},
}
</script>
