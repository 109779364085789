import {sendRequestJson} from "@/utils";

class FormRepository {
	constructor() {
		const host = window.location.hostname;
		if (host === "localhost"){
			this.urlBase = 'https://devdemo.wewelcom.io';
		} else {
			this.urlBase = window.location.origin;
		}
		console.log('MenuRepository con urlBase: ', this.urlBase)
	}

	async sendHomePageFormData(formData){
		const url = this.urlBase + '/world/api/landingform/create';

		try {
			return await sendRequestJson({data:formData, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal obteniendo enviando el formulario.");
		}
	}

	async sendClientRegisterPageFormData(formData){
		const url = this.urlBase + '/world/api/clientregisterform/create';

		try {
			return await sendRequestJson({data:formData, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal obteniendo enviando el formulario.");
		}
	}
}

export const formRepository = new FormRepository();
