import { createApp } from 'vue'
import App from './App.vue'
import {createRouter, createWebHistory} from "vue-router";
import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../public/assets/css/style.css';
import store from './store';
import SignupPage from "@/views/signup/Signup";
import HomePage from '@/views/index/Index'
import {faviconService} from "@/service/faviconService";
import {formRepository} from "@/service/repository/formRepository";

const routes = [
	{
		path: '/',
		component: HomePage,
		name: 'Home',
		meta: {
			title: 'WeWelcom | Digitally Humans'
		}
	},
	{
		path: '/alta/:subscription?',
		component: SignupPage,
		name: 'Signup',
		meta: {
			title: 'Alta de nuevos clientes'
		},
		/*beforeEnter: (to, from, next) => {
			const validSubscriptions = ['experience', 'pro', 'business'];
			if (!validSubscriptions.includes(to.params.subscription)) {
				next('/alta/pro'); // Redirect to default if invalid subscription
			} else {
				next();
			}
		}*/
	},
/*	{
		path: '/alta',
		redirect: '/alta/pro'
	}*/
]

const router = createRouter({
	history: createWebHistory(),
	routes
})

router.beforeEach(async (to, from, next) => {
	//let user = null;

	await store.dispatch('config/loadSystemConfig');

	console.log("Queremos cambiar el icono con "+store.getters['config/weWelcomUrl'])
	faviconService.changeFavicon(store.getters['config/weWelcomUrl'])

	//const uid = to.query.uid;

	document.title = to.meta.title || 'WeWelcom';

	switch (to.name){
		case "Home":
			break;
		case "Starter":
			break;
		case "Signup":
			console.log('Menu desde el switch');
			//user = await loadUser(uid);
			break;
		default:
			next('/');
			break;
	}

	next();
});

const app = createApp(App);
app.use(router);
app.use(store);
app.provide('formRepository', formRepository);
app.mount('#app');
