<NavbarWewelcom/>

<div>
	<!-- Formulario -->
	<form id="landing-form" @submit.prevent="submitForm">
		<section class="section-primary">
			<div class="container">
				<h1 class="text-center">Alta de nuevos {{ clientSignupName }}</h1>
				<div class="row-center">
					<img src="https://devdemo.wewelcom.io/world/img/wewelcom/logo/logo.png" alt="" class="logo">
				</div>
				<br>
				<br>
				<div v-if="subscriptionType !== 'Starter'">
					<h1 class="text-center">PRUÉBALO 2 MESES GRATIS</h1>
					<h1 class="text-center">SIN PERMANENCIA</h1>
				</div>
				<div v-else>
					<h1 class="text-center">INICIO POR CONFIRMAR</h1>
				</div>
			</div>
		</section>

		<section v-if="subscriptionType !== `Starter`" class="section-transparent m-0">
			<div class="container">
				<div class="align-items-center justify-content-center row">
					<div v-for="(productType,index) in availableSubscriptionTypes" :key="index" class="col-lg-5 col-md-6 py-3 mb-3" @click="selectSubscriptionType(productType)">
						<!--	Tarjeta 	-->
						<div v-if="productType && productType.name !== `Starter`" class="card-price-product" :class="{'active': subscriptionType === productType.name}">
							<div v-if="productType.discountInitial" class="product-discount"> -50%</div>
							<div class="product-price">
								<div v-if="productType.price != productType.initialPromoFee" class="product-price-content">
									<div class="underline-discount"></div>
									<span class="euro"><i class="fas fa-euro-sign"></i></span>
									<span class="number-price">{{ productType.price }}</span>
									<span class="month-price">/mes</span>
								</div>
								<div class="product-price-content">
									<span class="euro"><i class="fas fa-euro-sign"></i></span>
									<span class="number-price">{{ productType.initialPromoFee }}</span>
									<span class="month-price">/mes</span>
								</div>
								<div v-if="productType.discountInitial" class="duration-discount">
									durante 6 meses
								</div>
							</div>
							<h4 class="type-product" :class="productType && productType.name === 'Pro' ? 'type-product-secondary' : ''">
								{{ productType ? productType.name : 'Nombre no disponible' }}
							</h4>
							<div class="type-product-list-content">
								<ul>
									<li v-if="productType.name === 'Pro'">Ahorra 795€</li>
									<li v-else-if="productType.name === 'Experience'">&nbsp;</li>
									<li class="position-relative">
										<div v-if="!productType.highFee" class="underline-discount w-75"></div>Cuota de alta: 195€
									</li>
								</ul>
								<a href="#" class="btn btn-primary">Lo quiero</a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="container hidden">
				<div class="row form-row-content">
					<div class="col-12 col-md-10 col-lg-10">
						<div class="card-price-product restoo">
							<div class="product-price">
								<div class="product-price-content">
									<span class="euro"><i class="fas fa-euro-sign"></i></span>
									<span class="number-price">79</span> <span class="month-price">/mes</span>
								</div>
								<div class="type-product-list-content">
									<ul class="m-0">
										<li>We utiliza Restoo / tarifa aparte</li>
										<li>Gratis 60 días</li>
									</ul>
								</div>
							</div>
							<h4 class="type-product">RESTOO</h4>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section v-if="subscriptionType !== 'Starter'" class="section-transparent m-0 hidden">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-lg-10 col-md-10">
						<div class="section-primary">
							<h2>¿Qué Hardware deseas?</h2>
						</div>
						<div class="content-table-accessories card-price-product mb-3">
							<div class="content-img">
								<img src="https://lh7-us.googleusercontent.com/slidesz/AGV_vUdlAazfeG0gKi-8xOMuwjywe-S1WNhYry-KOugPcJnS4xze5wMhsiOC-thrdxu6RXuEzN294hKfuv_SDq3byU4NIK4o6enxGiE3oYECnIF4W9JkWAczucr8vJRhWzLhv4HYimYit-MDsoPNQlPupYn8dpKiMLg0=w1920-h967?key=PevjuGQ9lJHSDn2INJkW9A" alt="">
							</div>
							<div class="w-100">
								<ul class="list-group hidden display-xl list-group-horizontal content-accessories content-accessories-title">
									<div class="row g-0">
										<div class="col-3">
											<li class="list-group-item">HW</li>
										</div>
										<div class="col-3">
											<li class="list-group-item">Compra</li>
										</div>
										<div class="col-3">
											<li class="list-group-item">Alquiler(12m)</li>
										</div>
										<div class="col-3">
											<li class="list-group-item">Dispositivos</li>
										</div>
									</div>
								</ul>
								<ul v-for="(device, index) in availableHardware.devices" :key="device.name" class="list-group list-group-horizontal content-accessories">
									<div class="row w-100 g-0">
										<div class="col-12 col-lg-3">
											<li class="list-group-item accessories-name">{{ device.name }}</li>
										</div>
										<div class="col-4 col-lg-3">
											<li class="list-group-item">
												<div class="hidden-xl ">
													<label class="form-check-label">Compra</label>
												</div>
												<input class="form-check-input" type="radio" :name="`device_purchase_`+device.name" @change="onSelectHardware(device, 'device', $event.target.value, 'compra')" :value="device.priceBuy" :id="`device_buy_`+device.name" >
												<label class="form-check-label" :for="`device_buy_`+device.name">{{ device.priceBuy }}<i class="fas fa-euro-sign"></i></label>
											</li>
										</div>
										<div class="col-4 col-lg-3">
											<li class="list-group-item">
												<div class="hidden-xl">
													<label class="form-check-label">Alquiler ({{ device.rentPeriod }}m)</label>
												</div>
												<input class="form-check-input" type="radio" :name="`device_purchase_`+device.name" @change="onSelectHardware(device, 'device', $event.target.value, 'alquiler')" :value="device.priceRent" :id="`device_rent_`+device.name" >
												<label class="form-check-label" :for="`device_rent_`+device.name">{{ device.priceRent }}<i class="fas fa-euro-sign"></i>/m</label>
											</li>
										</div>
										<div class="col-4 col-lg-3">
											<li class="list-group-item content-select">
												<select class="form-select ps-2" @change="onSelectHardware(device, 'device', $event.target.value, 'quantity')" :id="`device_qty_`+device.name">
													<option selected value="">¿Cuántos?</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
													<option value="10">10</option>
												</select>
											</li>
										</div>
									</div>
								</ul>
								<ul class="list-group list-group-horizontal content-accessories">
									<div class="row w-100 g-0">
										<div class="col-12 text-center">
											<li class="list-group-item mt-3">
												<input class="form-check-input" type="checkbox" v-model="noDevicesNeeded" name="devices-needed" value="" id="devices-needed">
												<label class="form-check-label fs-4" for="devices-needed">De momento no necesito</label>
											</li>
										</div>
									</div>
								</ul>
							</div>
						</div>

						<div class="content-table-accessories card-price-product">
							<div class="content-img" style="height: 10rem">
								<img class="h-100" src="../../assets/stands.png" alt="">
							</div>
							<div class="w-100">
								<ul v-for="(apparel, index) in availableHardware.apparel" :key="apparel.name" class="list-group list-group-horizontal content-accessories">
									<div class="row w-100 g-0">
										<div class="col-12 col-lg-3">
											<li class="list-group-item accessories-name">{{ apparel.name }}</li>
										</div>
										<div class="col-4 col-lg-3">
											<li class="list-group-item">
												<div class="hidden-xl ">
													<label class="form-check-label" :for="`apparel_buy_`+apparel.name">Compra</label>
												</div>
												<input class="form-check-input" type="radio" :name="`apparel_purchase_`+apparel.name" @change="onSelectHardware(apparel, 'apparel', $event.target.value, 'compra')" :value="apparel.priceBuy" :id="`apparel_buy_`+apparel.name" >
												<label class="form-check-label" :for="`apparel_buy_`+apparel.name">{{ apparel.priceBuy }}<i class="fas fa-euro-sign"></i></label>
											</li>
										</div>
										<div class="col-4 col-lg-3">
											<li class="list-group-item">
												<div class="hidden-xl">
													<label class="form-check-label" :for="`apparel_rent_`+apparel.name">Alquiler ({{ apparel.rentPeriod }}m)</label>
												</div>
												<input class="form-check-input" type="radio" :name="`apparel_purchase_`+apparel.name" @change="onSelectHardware(apparel, 'apparel', $event.target.value, 'alquiler')" :value="apparel.priceRent" :id="`apparel_rent_`+apparel.name" >
												<label class="form-check-label" :for="`apparel_rent_`+apparel.name">{{ apparel.priceRent }}<i class="fas fa-euro-sign"></i>/m</label>
											</li>
										</div>
										<div class="col-4 col-lg-3">
											<li class="list-group-item content-select">
												<select class="form-select ps-2" @change="onSelectHardware(apparel, 'apparel', $event.target.value, 'quantity')" :id="`apparel_qty_`+apparel.name">
													<option selected value="">¿Cuántos?</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
													<option value="10">10</option>
												</select>
											</li>
										</div>
									</div>
								</ul>
								<ul class="list-group list-group-horizontal content-accessories">
									<div class="row w-100 g-0">
										<div class="col-12 text-center">
											<li class="list-group-item mt-3">
												<input class="form-check-input" type="checkbox" v-model="noApparelNeeded" name="apparel-needed" value="" id="apparel-needed">
												<label class="form-check-label fs-4" for="apparel-needed">De momento no necesito</label>
											</li>
										</div>
									</div>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<div :class="{'hidden': !showForm }">
			<section class="section-primary m-0">
				<div class="container">
					<div class="row form-row-content">
						<div class="col-12 col-md-10 mb-0">
							<h2>Datos de la empresa</h2>
						</div>
					</div>

					<div class="row form-row-content hidden">
						<div class="col-12  col-md-10">
							<div class="row">
								<div class="col-lg-auto">
									<div class="form-check form-check-inline">
										<input class="form-check-input " id="register-venue-type-restaurant" name="venueType" type="radio" value="Restaurante"
											   v-model="venueType"><label class="form-check-label" for="register-venue-type-restaurant">Restaurante</label>
									</div>
								</div>
								<div class="col-lg-auto">

									<div class="form-check form-check-inline">
										<input class="form-check-input " id="register-venue-type-hotel" name="venueType" type="radio"
											   value="Hotel" v-model="venueType"><label class="form-check-label" for="register-venue-type-hotel">Hotel</label>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="row row-column-gap form-row-content">
						<div class="col-12 col-md-5 col-lg-5 mb-0">
							<div class="input-group">
								<span class="input-group-text"><i class="fas fa-address-card"></i></span>
								<input id="signup-commercial-name" v-model="companyCommercialName" type="text" class="form-control" placeholder="Nombre Comercial*">
							</div>
						</div>

						<div class="col-12 col-md-5 col-lg-5">
							<div class="input-group"><span class="input-group-text"><i class="fas fa-building"></i></span>
								<input id="signup-business-name" v-model="companyLegalName" type="text" class="form-control" placeholder="Nombre Legal*">
							</div>
						</div>

						<div class="col-12 col-md-5 col-lg-5">
							<div class="input-group">
								<span class="input-group-text"><i class="fas fa-fingerprint"></i></span>
								<input id="signup-nif" v-model="companyFiscalId" type="text" class="form-control" placeholder="NIF*">
							</div>
						</div>

						<div class="col-12 col-md-5 col-lg-5">
							<div class="input-group">
								<span class="input-group-text"><i class="fas fa-globe-europe"></i></span>
								<input id="signup-website" v-model="companyWebsite" type="text" class="form-control" placeholder="Página web">
							</div>
						</div>
					</div>
				</div>
			</section>

			<section class="section-primary m-0">
				<div class="container">
					<div class="row form-row-content">
						<div class="col-12 col-md-10 mb-0">
							<h2>Domicilio fiscal</h2>
						</div>
					</div>

					<div class="row row-column-gap form-row-content">
						<div class="col-12 col-md-5 col-lg-5 mb-0">
							<div class="input-group">
								<span class="input-group-text"><i class="fas fa-map-marker-alt"></i></span>
								<input id="signup-address" v-model="companyAddress" type="text" class="form-control" placeholder="Dirección*">
							</div>
						</div>
						<div class="col-12 col-md-5 col-lg-5 mb-0">
							<div class="input-group">
								<span class="input-group-text"><i class="fas fa-map-marked-alt"></i></span>
								<input id="signup-location" v-model="companyLocation" type="text" class="form-control" placeholder="Localidad*">
							</div>
						</div>
						<div class="col-6 col-md-5 col-lg-5 mb-0">
							<div class="input-group"><span class="input-group-text"><i class="fas fa-map-marked"></i></span>
								<input id="signup-province" v-model="companyProvince" type="text" class="form-control" placeholder="Provincia*">
							</div>
						</div>
						<div class="col-6 col-md-5 col-lg-5 mb-0">
							<div class="input-group"><span class="input-group-text"><i class="fas fa-mail-bulk"></i></span>
								<input id="signup-postal-code" v-model="companyPostalCode" type="text" class="form-control" placeholder="Cód. Postal*">
							</div>
						</div>
						<div class="col-6 col-md-5 col-lg-5 mb-0">
							<div class="input-group"><span class="input-group-text"><i class="fas fa-flag"></i></span>
								<input id="signup-country" v-model="companyCountry" type="text" class="form-control" placeholder="País">
							</div>
						</div>
					</div>
				</div>
			</section>

			<section class="section-primary m-0">
				<div class="container">
					<div class="row form-row-content">
						<div class="col-12 col-md-10 mb-0">
							<h2>Representante legal</h2>
						</div>
					</div>

					<div class="row row-column-gap form-row-content">
						<div class="col-12 col-md-5 col-lg-5 mb-0">
							<div class="input-group"><span class="input-group-text"><i class="fas fa-user-tie"></i></span>
								<input id="signup-rep-name" v-model="repName" type="text" class="form-control" placeholder="Nombre*">
							</div>
						</div>
						<div class="col-12 col-md-5 col-lg-5 mb-0">
							<div class="input-group"><span class="input-group-text"><i class="fas fa-user-tag"></i></span>
								<input id="signup-rep-surname" v-model="repSurname" type="text" class="form-control" placeholder="Apellidos*">
							</div>
						</div>
						<div class="col-6 col-md-5 col-lg-5 mb-0">
							<div class="input-group"><span class="input-group-text"><i class="fas fa-envelope"></i></span>
								<input id="signup-rep-email" v-model="repEmail" type="text" class="form-control" placeholder="Email*">
							</div>
						</div>
						<div class="col-6 col-md-5 col-lg-5 mb-0">
							<div class="input-group"><span class="input-group-text"><i class="fas fa-phone"></i></span>
								<input id="signup-rep-phone" v-model="repPhone" type="text" class="form-control" placeholder="Teléfono*">
							</div>
						</div>
						<div class="col-6 col-md-5 col-lg-5 mb-0">
							<div class="input-group"><span class="input-group-text"><i class="fas fa-id-card"></i></span>
								<input id="signup-rep-nif" v-model="repFiscalId" type="text" class="form-control" placeholder="NIF*">
							</div>
						</div>
					</div>
				</div>
			</section>

			<section class="section-primary m-0 hidden">
				<div class="container">
					<div class="row form-row-content">
						<div class="col-12 col-md-10 mb-0">
							<h2>Tu URL WeWelcom:<br> {{ preRenderProposedSubdomain }}</h2>
						</div>
					</div>
					<!--				<div class="row row-column-gap form-row-content">
										<div class="col-12 col-md-5 col-lg-5 container-select">
											<i class="fas fa-map-pin icon-select text-dark"></i>
											<select v-model="subscriptionType" class="form-select" aria-label="">
												<option value="-1" selected disabled>Suscripción:</option>
												<option v-for="subscriptionTypee in availableSubscriptionTypes" :key="subscriptionTypee.name" :value="subscriptionTypee.name">
													{{ subscriptionTypee.name }}
												</option>
											</select>
										</div>
									</div>-->
				</div>
			</section>
			<section class="section-primary m-0">
				<div class="container">
					<div class="row row-column-gap form-row-content justify-content-center">
						<div class="col-10 ">
							<div id="restooInfoSection" class="form-check text-white">
								* WEWELCOM se integra con el sistema de reservas Restoo. El coste de este servicio <b>parte de 79€ + IVA (según versión)</b> que se factura de forma independiente.
							</div>
						</div>
					</div>
				</div>
			</section>
			<section class="section-primary m-0">
				<div class="container">
					<div class="row row-column-gap form-row-content justify-content-center">
						<div class="col-auto col-md-6 col-lg-6">
							<div id="signupTermsSection" class="form-check text-white">
								<input id="signup-terms" class="form-check-input" v-model="signupTerms" type="checkbox" value="signup-terms">
								<label class="form-check-label" for="signup-terms">Leo y acepto la</label>
								<a data-bs-toggle="modal" data-bs-target="#privacy-terms-modal" class="f-no-utm" href="#termsPrivacy"> Política de Privacidad de WeWelcom</a>
								y el
								<a data-bs-toggle="modal" data-bs-target="#saas-contract-modal" class="f-no-utm" href="#termsPrivacy"> Contrato de Suscripción de Licencia</a>
							</div>
						</div>
					</div>

					<div :class="{ 'hidden': !showErrorMessageForm }"
						 class="alert alert-danger" role="alert">
						{{ errorMessage }}
					</div>
					<div :class="{ 'hidden': !showSuccessMessageForm }"
						 class="alert alert-success" role="alert">
						{{ successMessage }}
					</div>

					<div class="content-center">
						<button id="submitFormButton" @click:prevent="submitForm" class="btn btn-secondary ">Solicitar alta en WeWelcom</button>
					</div>
				</div>
			</section>
		</div>
	</form>
</div>

<!-- Modal Content PRIVACY -->
<div class="modal fade" id="privacy-terms-modal" tabindex="-1" aria-labelledby="privacy-terms-modal-label"
	 aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h1 class="modal-title"><i
					class="fa icon-welkhome-club-pertenece align-items-center align-self-center justify-content-center"></i>Política
																															de Privacidad
				</h1>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="container">
					<div class="row">
						<div class="col-12">
							<p>
								La Política de Privacidad forma parte de las Condiciones Generales que rigen la presente
								Web
								www.wewelcom.io
								Última versión de enero 2024
							</p>
							<h4>¿Quién es el responsable del tratamiento del tratamiento de los datos?</h4>
							<p>
								Todos los datos facilitados por los Usuarios en los diferentes formularios, solicitudes
								de
								información, inscripción a Newsletter, para ponernos en contacto contigo o bien
								facilitarnos tu
								Curriculum que se encuentran en serán incorporados y tratados, tanto a través de medios
								telemáticos, informáticos y no automatizados, siendo el responsable de dichos
								tratamientos:
							</p>
							<ul class="style-ok">
								<li>Responsable: Verywel Tech S.L.</li>
								<li>Dirección: Calle Monte Esquinza 15, CP: 28010, Madrid</li>
								<li>CIF: B44990042</li>
							</ul>
							<p>
								Para cualquier duda o consulta, puedes ponerte en contacto con nosotros a través del
								siguiente E-mail: rgpd@wewelcom.io
							</p>
							<h4>
								¿Quién puede facilitarnos datos?
							</h4>
							<p>
								El acceso de los Usuarios a la página web puede implicar el tratamiento de datos de
								carácter
								personal. Al acceder a nuestra página, manifiestas que eres mayor de edad y que todos
								los
								datos que nos facilites, bien sean obligatorios para prestarte el servicio o información
								o bien
								datos adicionales que completan tu información y solicitud, son veraces y correctos.
								Estos
								datos nos los proporcionas voluntariamente.
								Para determinados tratamientos de datos te solicitaremos que nos otorgues tu
								consentimiento
								y para determinadas finalidades, te solicitaremos tu autorización a través de la
								activación de
								las casillas de validación específicas.
							</p>
							<h4>
								¿Qué datos recopilamos?
							</h4>
							<p>
								Al enviar una consulta de información, ponerte en contacto con nosotros a través de
								correo
								electrónico o por teléfono, que respondamos a tus solicitudes, nos envíes tu candidatura
								espontánea de empleo, contrates nuestros servicios, podemos solicitarle que nos
								facilites una
								serie de datos e información personal tal como tu nombre, apellidos, dirección, email,
								teléfono
								contacto, entre otros.
							</p>
							<h4>
								¿Cómo se usa la información y datos que nos facilitas?
							</h4>
							<p>
								Verywel Tech S.L. usará la información que nos proporcionas para las siguientes
								finalidades:
							</p>
							<h5>Tratamiento de datos de usuario para responder a tus solicitudes de información.</h5>
							<p>
								Trataremos tus datos para responder a tus solicitudes de información y ponernos en
								contacto
								contigo para remitirte información sobre nuestros productos y servicios, hacer un
								seguimiento
								de las mismas.

								La base que legitima este tratamiento es tu consentimiento.
								Tus datos serán conservados mientras se mantenga vigente el consentimiento; una vez
								producida tu baja voluntaria, serán bloqueados por un plazo de 2 años, siendo
								posteriormente
								eliminados.
							</p>
							<h5>
								Tratamiento de datos para contratación de servicios a través de la web www.wewelcom.io
							</h5>
							<p>
								Trataremos sus datos, con tu consentimiento expreso, para la tramitación y contratación
								de

								productos y servicios de Verywel Tech S.L., así como la prestación de nuestros
								servicios.
								La base que legitima el tratamiento es tu consentimiento; tus datos serán conservados,
								una
								vez finalizados los servicios contratados, por un plazo mínimo de 2 años;
								posteriormente,
								previo proceso de disociación, la información sobre servicios prestados será tratada a
								efectos
								estadísticos.
							</p>
							<h5>
								Tratamiento de datos para comunicaciones de productos y servicios de pertenecientes a la
								web www.wewelcom.io.
							</h5>
							<p>
								Trataremos sus datos, con tu consentimiento expreso, para remitirte comunicaciones de
								productos y servicios de pertenecientes a la web www.wewelcom.io.
								La base que legitima el tratamiento es tu consentimiento; en cualquier momento podrás
								darte
								de baja siguiendo las instrucciones que aparecen al final de todas las comunicaciones
								electrónicas o solicitándolo por escrito, como se describe en el apartado Derechos.
							</p>
							<h5>
								Tratamiento de datos CV de candidatos.
							</h5>
							<p>
								Trataremos tus datos, con tu consentimiento expreso, para finalidades de participación
								en
								procesos de selección de personal realizados por Verywel Tech S.L. El consentimiento es
								otorgado directamente por el candidato al remitir sus datos y Curriculum Vitae a la
								dirección:
								rgpd@wewelcom.io
								La base que legitima el tratamiento es tu consentimiento; en cualquier momento podrá
								revocar este consentimiento.
							</p>
							<h5>
								Contactos de la o del correo electrónico.
							</h5>
							<p>
								Trataremos los datos identificativos y de contacto que el usuario proporcione al ponerse
								en
								contacto con Verywel Tech S.L. mediante correo electrónico o telefónicamente, así como
								otros
								datos que el usuario incluya. Los datos serán tratados con las siguientes finalidad:
							</p>
							<ul class="style-ok">
								<li>Contestar consultas, solicitudes o peticiones.</li>
								<li>Darle de alta y registrarte como Usuario</li>
								<li>Gestionar el servicio solicitado, contestar solicitudes, o tramitar peticiones.</li>
								<li>Información por medios electrónicos, que versen sobre una solicitud.</li>
								<li>Realizar análisis y mejoras en la Web, sobre los servicios de Verywel Tech S.L.</li>
								<li>Información comercial por medios electrónicos, siempre que exista autorización
									expresa.
								</li>
							</ul>
							<p>
								Nuestro formulario de contacto cuenta con el símbolo * en los campos obligatorios. Si no
								se
								facilitan esos campos, no nos será posible contestar a la petición y por tanto, no se
								permitirá
								el envío del formulario.
								La base que legitima el tratamiento es tu consentimiento, que podrá ser revocado en
								cualquier
								momento.
							</p>
							<h5>
								Contactos de Redes Sociales.
							</h5>
							<p>
								Los datos personales disponibles en los perfiles de RRSS, así como aquellos que el
								usuario
								proporcione al Responsable al contactar con el mediante esta vía, serán tratados con la
								finalidad de:
							</p>
							<ul class="style-ok">
								<li>Contestar a consultas, solicitudes o peticiones.</li>
								<li>Gestionar el servicio solicitado, contestar a la solicitud, o tramitar una
									petición.
								</li>
								<li>Establecer una relación usuario-responsable, y crear una comunidad de seguidores.
								</li>
							</ul>
							<p>
								En este caso el tratamiento, se basa en la aceptación de una relación contractual en el
								entorno
								de la red social que corresponda y conforme a sus políticas de Privacidad, por lo que es
								recomendable que el usuario consulte las mismas.
								Verywel Tech S.L. únicamente podrá consultar o dar de baja los datos de forma
								restringida al
								tener un perfil específico. Estos serán tratados tanto tiempo como el usuario lo permita
								mediante las distintas interacciones que cada RRSS permita. Cualquier rectificación de
								los
								datos o restricción de información o de publicaciones, el usuario debe realizarla a
								través de la
								configuración de su perfil en la propia red social.
							</p>
							<h5>
								Tratamiento de datos de clientes y proveedores.
							</h5>
							<p>
								Trataremos los datos identificativos, de contacto y de facturación, así como todos los
								datos del
								cliente/proveedor estrictamente necesarios para el correcto desarrollo de la relación
								contractual, con las siguientes finalidades:
							</p>
							<ul class="style-ok">
								<li>Elaboración del presupuesto y seguimiento del mismo mediante comunicaciones entre
									las partes.
								</li>
								<li>Si realiza una orden de nuestros productos y servicios, o si proporciona servicios a
									Verywel Tech S.L., a nuestros empleados, clientes o socios como proveedor,
									procesaremos la información personal con el objetivo de completar las transacciones
									pertinentes, así como de administrarlas (por ejemplo, mediante el envío de facturas
									y
									la formalización de pagos o la entrega del producto o prestación del servicio), y
									gestionar correctamente el producto o servicio solicitado.
								</li>
								<li>Información por medios electrónicos, para el correcto desarrollo de la relación
									contractual o que puedan guardar relación con el producto/servicio objeto de la
									misma, en base al interés legítimo del responsable.
								</li>
								<li>Facturación y declaración de los impuestos oportunos. Los datos bancarios serán
									utilizados para la ejecución del servicio solicitado, como pueda ser el pago/cobro
									de
									un producto/servicio.
								</li>
								<li>Gestiones de control y recobro.</li>
							</ul>
							<p>
								La base legal es la ejecución o desarrollo de una relación contractual, o en su defecto
								el
								consentimiento al contactar con nosotros u ofrecernos productos. En caso de no
								proporcionar

								alguno de los datos necesarios para el correcto desarrollo del contrato, no podremos
								proceder
								a la prestación del servicio solicitado.
							</p>
							<h4>
								¿Incluimos datos personales de terceras personas?
							</h4>
							<p>
								No, como norma general sólo tratamos los datos que nos facilitan los titulares. Si se
								aportasen
								datos de terceros, se deberá con carácter previo, informar y solicitar su consentimiento
								a
								dichas personas, o de lo contrario Verywel Tech S.L. quedará exento de cualquier
								responsabilidad por el incumplimiento de este requisito.
							</p>
							<h4>
								¿Y datos de menores?
							</h4>
							<p>
								No tratamos datos de menores de 14 años. Por tanto, absténgase de facilitarlos si no
								tiene esa
								edad o, en su caso, de facilitar datos de terceros que no tengan la citada edad. Verywel
								Tech
								S.L. se exime de cualquier responsabilidad por el incumplimiento de esta previsión.
							</p>
							<h4>
								¿Cómo y dónde se comparte la información?
							</h4>
							<p>
								Salvo que el usuario autorice a la recepción de comunicaciones de productos y servicios
								por las
								Entidades que conforman el Grupo para que puedan remitirte información de los productos
								y
								servicios, Verywel Tech S.L. te informa, de manera expresa, que únicamente comunicará
								los
								datos a aquellos organismos y entes de la Administración Pública con competencia en la
								materia de acuerdo con la normativa legal vigente.
								Los datos personales podrán ser comunicados a encargados del tratamiento necesarios para
								la
								prestación del servicio, quienes tendrán firmado un contrato de prestación de servicios
								que les
								obliga a mantener el mismo nivel de privacidad que el del responsable.
							</p>
							<h4>
								Seguridad de tus datos
							</h4>
							<p>
								Verywel Tech S.L. da gran importancia a la seguridad de toda la información de
								identificación
								personal. Desafortunadamente, la transmisión de información a través de Internet no es
								completamente segura; por ello, hacemos esfuerzos y destinamos recursos a mejorar cada
								día
								nuestra página y que las funcionalidades que ponemos a tu disposición sean más seguras.
								Una vez que hayamos recibido tu información, sobre dichos datos hemos implementado
								medidas de seguridad para garantizar el acceso, confidencialidad, integridad y evitar
								cualquier
								pérdida, mal uso y/o alteración de los datos que están bajo nuestro control. Por
								ejemplo,
								nuestras políticas de seguridad y privacidad son revisadas periódicamente y mejoradas
								según
								sea necesario y sólo el personal autorizado tiene acceso a la información del usuario.
							</p>
							<h4>
								Datos de navegación
							</h4>
							<p>
								Verywel Tech S.L. solamente registrará datos anónimos con respecto a la navegación de
								los
								usuarios, con el fin de obtener datos estadísticos sobre fecha y hora de acceso al Web
								Site,
								navegación, visitas, uso de funcionalidades, descargas, visualizaciones. Además, con el
								fin de
								conocer la eficacia de los banners y links que apuntan a nuestro sitio, identificaremos
								también
								el link de procedencia de la visita. Toda la información obtenida es completamente
								anónima, y
								en ningún caso podría ser utilizada para identificar a un usuario concreto ni es posible
								asociarla
								con una persona en particular, simplemente nos servirá para mejorar el servicio que
								ofrecemos a nuestros usuarios.

								Te informamos, asimismo, que durante la navegación y visita a las páginas de Verywel
								Tech S.L.
								podemos utilizar balizas de seguimiento y cookies, que nos permiten adecuar nuestros
								servicios y productos a las preferencias e intereses de los usuarios y visitantes. En
								caso de
								visitantes de la página, los datos que nos proporcionan estas balizas de seguimiento no
								pueden
								ser asociados a una persona concreta ni nos permiten obtener una identificación directa
								del
								usuario.
							</p>
							<h4>
								Actualización en la contratación de servicio prestado
							</h4>
							<p>
								Para aquellas situaciones donde Verywel Tech S.L. deba prestar al Cliente unos servicios
								de
								naturaleza y características distintas a los propios de Verywel Tech S.L. es posible que
								requieran la adaptación de la relación entre ambas partes a efectos de privacidad. En
								este
								sentido, Verywel Tech S.L. y el Cliente acordarán la preparación de un acuerdo en que
								queden
								reguladas las correspondientes obligaciones, responsabilidades y derechos aplicables a
								cada
								una de las partes en materia de Protección de Datos de Carácter Personal.
							</p>
							<h4>
								Acuerdo de confidencialidad
							</h4>
							<p>
								Verywel Tech S.L. y el Cliente se comprometen a mantener reservada y confidencial la
								existencia y el contenido de toda la documentación e información que se facilite,
								transmita o
								divulgue con independencia del método, forma o soporte utilizado (en adelante, &quot;Información
								Confidencial&quot;), comprometiéndose a no realizar ninguna divulgación a terceros o
								comunicación pública sin la previa autorización por escrito de la otra parte.
								De forma enunciativa pero no limitativa, se entenderá como Información Confidencial la
								información referida a datos de Clientes, su existencia, su estructura, planes de
								promoción y
								venta, códigos fuente y objeto de programas informáticos, sistemas, técnicas, Propiedad
								Industrial e Intelectual, datos técnicos y no técnicos, dibujos, bocetos, datos
								financieros,
								planes relativos a nuevos productos, datos relativos a clientes o potenciales Clientes
								así como
								cualquier otra información utilizada en el ámbito empresarial de Verywel Tech S.L.
								La obligación de confidencialidad subsistirá incluso después de la resolución, por
								cualquier
								causa, de la relación contractual entre las partes.
								El incumplimiento de la obligación de confidencialidad asumida en estos Términos y
								Condiciones o la devolución de la Información Confidencial establecida anteriormente
								dará
								derecho a cualquiera de las partes a reclamar los daños y perjuicios que dicho
								incumplimiento
								hubiera generado según los límites establecidos en la normativa vigente.
								La obligación de confidencialidad no será de aplicación en los casos en los que:
								Después de haber sido facilitada como Información Confidencial, deviniese accesible
								públicamente, sin que en dicha circunstancia hubiese intervenido incumplimiento alguno
								de la
								presente cláusula; o
								Se encontrare legalmente en posesión de la parte receptora en el momento en que hubiese
								sido facilitada por la parte emisora, o que hubiese sido obtenida por aquella de manera
								independiente y anterior a haberle sido facilitada por esta; o
								Que la parte receptora demuestre haberla obtenido legalmente de modo no restringido de
								cualquier tercero que no estuviese sujeto por obligaciones de confidencialidad similares
								con la
								parte emisora; o

								Que deba ser obligatoriamente facilitada en virtud de disposición legal o por resolución
								válidamente emitida por cualquier autoridad administrativa competente, tribunal u órgano
								jurisdiccional, legalmente facultado para obligar a tal disponibilidad, siempre y cuando
								la parte
								receptora así requerida notifique inmediatamente a la parte emisora la recepción de tal
								requerimiento, a fin de que la parte emisora pueda evaluar si existe posibilidad de
								eludir el
								mismo o pueda prestar cualquier apoyo razonablemente solicitado por la parte receptora.
							</p>
							<h4>
								Tus Derechos y el acceso a la información
							</h4>
							<p>
								De acuerdo con la normativa vigente, Reglamento UE 679/2016, de 27 de abril, General de
								Protección de Datos y a con la Ley Orgánica 3/2018, de Protección de Datos Personales y
								Garantía de los Derechos Digitales tienes la posibilidad de ejercitar los siguientes
								derechos
								personalísimos, acreditando de manera fehaciente tu identidad, bien a través de medios
								electrónicos (en el caso de Newsletter, comunicaciones y correos electrónicos) o bien a
								través
								de comunicación por escrito dirigida a nuestro Responsable de Privacidad. Tus derechos
								son
								los siguientes:
							</p>
							<ul class="style-ok">
								<li>Derecho a revocar tu consentimiento y solicitarnos que no procesemos tus datos.</li>
								<li>Derecho de acceso a la información que disponemos referente a tu persona, con
									especificación de las finalidades del tratamiento que realizamos y las
									comunicaciones
									que hemos realizado a terceras administraciones y empresas, bien por encontrarse
									autorizadas por ley o por ser necesarias para la prestación del servicio que nos has
									solicitado, bien por haber otorgado tu consentimiento.
								</li>
								<li>Derecho de rectificación de tus datos, por el que actualizaremos y pondremos al día
									la
									información y datos que disponemos según la información que nos facilites.
								</li>
								<li>Derecho a la supresión de tus datos, una cumplidos los plazos legales de
									conservación
									fijados por la normativa de aplicación.
								</li>
								<li>Derecho a oponerte a que realicemos cualquier actividad de tratamiento de tus datos,
									a través de la revocación de tu autorización/consentimiento.
								</li>
								<li>Derecho a limitar el tratamiento de tus datos, como en caso de que interpongas una
									reclamación o quieras que conservemos tus datos por un plazo superior, evitando su
									supresión.
								</li>
								<li>Derecho a la portabilidad de tus datos, por el que te facilitaremos en un formato de
									archivo de lectura común aquellos datos que nos has facilitado en el momento de tu
									registro como usuario.
								</li>
							</ul>
							<p>
								Además, te informamos que siempre podrás acudir a la Autoridad de Control Española de
								Protección de Datos (Agencia Española de Protección de Datos, www.agpd.es) para
								solicitar su
								tutela o interponer una reclamación en relación al tratamiento de datos personales.
							</p>
							<h4>
								Responsable de Privacidad
							</h4>
							<p>
								En cualquier momento nos podrás solicitar, de manera totalmente gratuita, bien por carta
								o a
								través de correo electrónico, el ejercicio de sus derechos. Tus solicitudes deberás
								enviarlas a
								nuestro Responsable de Privacidad en la siguiente dirección:
							</p>
							<ul class="style-ok">
								<li>Responsable: Verywel Tech S.L.</li>
								<li>Dirección: Calle Monte Esquinza 15, CP: 28010, Madrid</li>
								<li>CIF: B44990042</li>
								<li>Email: rgpd@wewelcom.io</li>
							</ul>
							<h4>
								Cambios en la Política de Privacidad
							</h4>
							<p>

								De vez en cuando podremos modificar o adaptar nuestra Política de Privacidad y
								Tratamiento
								de datos de Carácter Personal. Las modificaciones serán comunicadas a nuestros usuarios.
							</p>

						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer text-end">
				<button type="button" class="btn btn-border btn-outline-primary " data-bs-dismiss="modal">Salir</button>
			</div>
		</div>
	</div>
</div>
<!-- END Modal Content PRIVACY -->

<!-- Modal Content PRIVACY -->
<div class="modal fade" id="saas-contract-modal" tabindex="-1" aria-labelledby="saas-contract-modal-label"
	 aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h1 class="modal-title"><i
					class="fa icon-welkhome-club-pertenece align-items-center align-self-center justify-content-center"></i>Contrato de Suscripción de Licencia
				</h1>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="container">
					<div class="row">
						<div class="col-12">
							<h4>ACUERDOS ENTRE LAS PARTES</h4>
							<h4>1. OBJETO</h4>
							<p>El presente contrato tiene por objeto establecer los términos y condiciones por los que se regirá la prestación de servicio por WeWelcom a EL CLIENTE mediante el uso del software de gestión (WEcopilot).</p>
							<h4>2. CONCESIÓN DE LICENCIA Y PROPIEDAD INTELECTUAL</h4>
							<p>1. Para la correcta prestación de los Servicios, WeWelcom concede al Cliente (i) con carácter no exclusivo, (ii) de manera intransferible y (iii) durante la vigencia del presente Contrato, una licencia de uso del software propiedad de WeWelcom (la“Licencia”).</p>
							<p>2. EL CLIENTE se obliga a utilizar los Servicios contratados, únicamente para su propio
							   uso empresarial y sujeto a las condiciones de este Contrato. Todos los derechos no
							   concedidos expresamente quedan reservados a WeWelcom.</p>
							<p>3. WeWelcom conservará en
							   todo momento la titularidad de todos los derechos de propiedad intelectual sobre los
							   Servicios, y el Software utilizado, objeto de este Contrato.</p>
							<p>4. Las Partes reconocen
							   que la titularidad del Software, así como cada uno de sus elementos integrantes, sus
							   actualizaciones, código fuente, nuevas versiones, procesos técnicos y/o operativos,
							   imágenes, fotografías, animaciones, módulos, vídeos, audio, música, texto, y applets o
							   subprogramas incorporados a la misma, así como los posibles materiales impresos o en formato
							   electrónico o digital y toda copia del mismo, forman parte de la propiedad intelectual de
							   WeWelcom quien conserva todos sus derechos, y cuya utilización se permite al Cliente dentro
							   de los límites establecidos en el Contrato. Todo ello sin perjuicio de los programas que, de
							   código abierto, pueda emplear WeWelcom para la configuración de su estructura</p>
							<p>del Software, en cuyo caso, corresponderá a su legítimo titular.</p>
							<p>5. Toda
							   modificación, mejora, ampliación o modificación que sufra el Software durante el plazo de la
							   Licencia, aún con la participación del Cliente, será única y exclusivamente propiedad de
							   WeWelcom, sin que ello genere en favor del Cliente derecho alguno por el cual reclamar.</p>
							<p>6. El Cliente debe abstenerse de copiar con o sin fines de lucro los Servicios, y el
							   Software utilizado, dictando las medidas internas necesarias tendientes a la protección
							   de los derechos de autor de los propietarios de los programas bajo licencia.</p>
							<p>7.
							   El Cliente deberá notificar de forma inmediata a WeWelcom cualquier reclamación de terceros
							   por presunta infracción de derechos de propiedad intelectual y colaborar de forma estrecha
							   con WeWelcom proporcionándole toda la información de que disponga en relación con dicha
							   reclamación.</p>
							<p>8. En atención a lo anterior, el Cliente en ningún caso (i) podrá
							   ceder, licenciar, sublicenciar, transmitir, transferir, asignar distribuir o explotar
							   comercialmente de cualquier otra manera o poner a disposición de terceros el Software y/o
							   los Servicios; (ii) realizar ingeniería inversa sobre el Software y el servicio para crear
							   un producto utilizando ideas, características o funciones similares a las contenidas en el
							   objeto del presente contrato ni (iii) interferir o afectar a la integridad o rendimiento del
							   servicio objeto del</p>
							<p>presente Contrato.</p>
							<p>9. El Cliente declara ser el responsable de su sitio web en el
							   sentido de la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y
							   de comercio electrónico. En esta condición, el Cliente asume toda la responsabilidad
							   editorial del Sitio Web con fines comerciales.</p>
							<h4>3. RESPONSABILIDAD</h4>
							<p>EL CLIENTE tendrá que eximir a WEWELCOM y a cada organización principal, filial,
							   afiliado, directivo, director, empleado, representante legal y agente de éstos de toda
							   responsabilidad derivada de cualquier reclamación, costes, daños y perjuicios, pérdidas,
							   obligaciones y gastos (incluidos los honorarios y las costas) surgidos a raíz de: (i)
							   una reclamación que alega que el uso de los Datos de EL CLIENTE infringe los derechos de
							   terceros o ha provocado daños a terceros; (ii) una reclamación que, en caso</p>
							<p>de ser cierta, constituiría el incumplimiento de sus declaraciones; o (iii) una
							   reclamación derivada del incumplimiento de este Contrato por parte del cliente o de sus
							   clientes secundarios, siempre que en ese caso WEWELCOM (a) le notifique por escrito la
							   reclamación de forma inmediata; (b) le otorgue control exclusivo de la alegación y
							   resolución de la reclamación (siempre que no resuelva o alegue ninguna reclamación a
							   menos que exima de toda responsabilidad a WEWELCOM de forma incondicional y dicha
							   resolución no afecte al Servicio o la actividad de WEWELCOM); (c) le ofrezca toda la
							   información y ayuda de la que dispone; y (d) no haya comprometido o resuelto dicha
							   reclamación.</p>

							<h4>4. CARACTERÍSTICAS DEL SERVICIO</h4>
							<p>1. El software de gestión de
							   clientes del que se cede el derecho para la explotación y que se denomina WEcopilot, está
							   compuesto, en lo que a tecnología se refiere por:</p>
							<ul>
								<li>SmartMenu para consumidores</li>
								<li>WEcopilot para los trabajadores</li>
								<li>Dashboard para los decisores</li>
							</ul>

							<p>Groso modo y de modo meramente enunciativo, el software permite: leer QRs, Listar las
							   reservas, aceptar walk-ins y sentarlos automáticamente en plano, recibir los pedidos del
							   SmartMenu, guía auditiva de todos los procesos y acceso al panel de datos.</p>
							<p>2. Una
							   de las características principales del software WEcopilot es la posibilidad de implementar
							   un módulo de reservas en la web de EL CLIENTE. Para ello, es necesario que o bien EL CLIENTE
							   otorgue acceso a WEWELCOM con sus credenciales o bien que ponga en contacto a su equipo
							   técnico para que sean ellos los que implementen el código de implementación facilitado por
							   WEWELCOM. En ambos casos, y de forma expresa, se exime al PROVEEDOR de cuantos fallos
							   pudieran derivarse de dicha implementación en el site web antes, durante y después del
							   mismo.</p>
							<p>3. Cesión de equipo hardware:</p>
							<p>En caso de que WEWELCOM acuerde ceder a EL CLIENTE, sin costo alguno (0,00€), los equipos
							   hardware específicos, consistentes en un móvil y un pinganillo, necesarios para el uso
							   eficiente del software WEcopilot. Estos equipos son proporcionados para su uso durante
							   la vigencia de este contrato y en las condiciones aquí estipuladas.</p>
							<p>En caso de pérdida, daño o sustracción de cualquiera de estos equipos, EL CLIENTE se
							   compromete a abonar a WEWELCOM la cantidad de cien euros (100,00€) por equipo afectado.
							   EL CLIENTE deberá notificar cualquier incidencia relacionada con el equipo sin demora
							   injustificada.</p>

							<h4>5. FACTURACIÓN y TARIFAS</h4>
							<p>1. La cesión de la licencia de
							   uso proporcionada por WEWELCOM a EL CLIENTE genera una tarifa mensual base según la versión
							   elegida en la oferta económica, gestionado mediante recibo domiciliado bancario. Con este
							   fin, EL CLIENTE autoriza expresamente a Verywel Tech SL a emitir con cargo a mi cuenta los
							   recibos domiciliados según normativa SEPA Ley de Pagos 16-2009 adjunto en el Anexo II del
							   presente contrato.</p>
							<p>2. Los gastos devengados por devoluciones de domiciliación
							   bancaria correrán a cargo de EL CLIENTE.</p>
							<p>3. WeWelcom emitirá la factura en soporte
							   digital y la enviará a la dirección de correo electrónico de EL CLIENTE mensualmente para
							   cada uno de sus locales.</p>
							<p>4. Los precios indicados no incluyen 21% de IVA.</p>
							<p>5.
							   WeWelcom se reserva el derecho de actualizar unilateralmente las tarifas de los servicios,
							   debiendo notificar por escrito al Cliente dichas actualizaciones en el plazo de dos (2)
							   meses a la fecha prevista para su entrada en vigor, entendiéndose aceptadas por el Cliente
							   si no media oposición en los 15 días naturales siguientes a su recepción.</p>
							<p>6. En caso
							   de que el Cliente deseara la contratación de servicios o funcionalidades adicionales se
							   pondrá en contacto con su gestor de WeWelcom indicando la petición de nuevos servicios a
							   contratar. WeWelcom remitirá en tal sentido una comunicación por escrito ratificando los
							   servicios o funcionalidades contratadas así como el coste de los mismos al Cliente quien
							   deberá aceptar por el mismo medio que le sea remitida, procediéndose en tal momento a la
							   activación formal de los nuevos servicios o funcionalidades contratados</p>
							<p>7. WeWelcom
							   podrá seguir desarrollando nuevos servicios o funcionalidades, que no están incluidas en el
							   presente Contrato, y por las cuales, las Partes podrán fijar una remuneración adicional. La
							   activación de los nuevos servicios o funcionalidades deberá constar por escrito junto a las
							   tarifas que en cada momento resulten aplicables.</p>

							<p>8. Restoo se facturará de forma independiente a WEWELCOM, contratándose por defecto el Plan PRO,
								siendo éste el mínimo necesario. En caso de ser necesario un upgrade por necesidades del cliente,
								las tarifas de Restoo para los diferentes planes son las siguientes:</p>
							<ul>
								<li>Plan MINI: 39€</li>
								<li>Plan PRO: 79€</li>
								<li>Plan PREMIUM: 149€</li>
								<li>Plan GOLD: 249€</li>
							</ul>
							<p>Los precios indicados de Restoo no incluyen el 21% de IVA</p>
							<h4>6. CONFIDENCIALIDAD</h4>
							<p>Las partes acuerdan que cualquier información relativa a los aspectos comerciales
							   (precios de productos y servicios) suministrada a la otra parte como consecuencia de la
							   firma de este contrato tendrá consideración de información confidencial entre las dos
							   partes y será tratada de acuerdo con lo establecido en el presente documento.</p>

							<h4>7. LIMITACIÓN DE LA RESPONSABILIDAD</h4>
							<p>1. WeWelcom no será responsable de los perjuicios o
							   daños provenientes de las transacciones efectuadas entre el Cliente y sus usuarios o
							   terceros, vinculadas al uso del Software, limitando su actuación a lo que expresamente se
							   obliga en el presente Contrato.</p>
							<p>2. WeWelcom no será responsable de los fallos y/o
							   incidencias derivados del uso incorrecto del Software por parte del Cliente o sus usuarios.
							   Asimismo, WeWelcom no será responsable, ni frente a Cliente ni frente a terceros, de los
							   daños, perjuicios y pérdidas que pudieran derivarse de los siguientes supuestos, sin
							   limitación: (i) fallo de la red internet; (ii) uso por parte del Cliente de material
							   informático no adaptado, defectuoso o no actualizado; (iii) fallos en la implementación en
							   la web del Cliente de los Iframe de servicios del Software; (iv) incumplimiento por parte
							   del Cliente de la legislación vigente o cualquier otra normativa aplicable en materia de
							   protección de datos personales; (v) incumplimiento del Contrato por parte del Cliente, su
							   personal o subcontratistas contratados por el Cliente; (vi) el correcto funcionamiento de
							   los servicios, materiales, componentes y equipos que hayan sido adquiridos por el Cliente a
							   terceros; (vii) cualquier suceso que escape de la esfera de control de WeWelcom.</p>
							<p>3.
							   WeWelcom no asumirá responsabilidad de ningún tipo, cuando debido al acceso al Software o
							   por cualquier transferencia de datos, los equipos informáticos del Cliente se viesen
							   afectados por algún virus, o por la presencia de otros elementos que puedan producir
							   alteraciones en el sistema informático, documentos electrónicos o archivos del Cliente,
							   siempre que resulten por causas no imputables a WeWelcom.</p>
							<p>4. WeWelcom responderá de
							   los daños y perjuicios ocasionados al Cliente como consecuencia de cualquier incumplimiento
							   contractual debido directamente a una conducta dolosa o negligente de WeWelcom.</p>
							<p>5.
							   WeWelcom declina toda responsabilidad en caso de indisponibilidad del Servicio y del
							   Software por motivos de fuerza mayor, por la interrupción prolongada del suministro
							   eléctrico, la interrupción de la red de telecomunicaciones o la pérdida de conexión a
							   internet imputables a los operadores públicos o privados que prestan sus servicios a
							   WeWelcom, de manera que impidan al Software cumplir con su finalidad, siempre y cuando dicha
							   indisponibilidad no sea atribuible a WeWelcom.</p>
							<p>6. A petición del Cliente, WeWelcom
							   hará sus mejores esfuerzos para integrar en la Api de WEcopilot los diversos aplicativos,
							   programas o funcionalidades de gestión de terceros con el ánimo de prestar al Cliente un
							   servicio más completo o integrado. Aun cuando WeWelcom hará sus mejores esfuerzos para que
							   dicha integración sea completa y efectiva, no se puede responsabilizar de las integraciones
							   previas realizadas por el Cliente o de la inexistencia de fallos que se pudieran producir al
							   llevar a cabo la integración en el software, declinando por tanto toda responsabilidad que
							   se derive de fallos o mermas en el aplicativo resultante que no se deriven de errores del
							   software, sin perjuicio de lo cual hará sus mejores esfuerzos para que dicha integración o
							   en su caso los fallos que se pudieran producir afecten lo menos posible a la funcionalidad
							   del aplicativo.</p>
							<p>7. Por último, WeWelcom, en ningún caso, responderá por la falta de
							   cumplimiento de las expectativas depositadas por el CLIENTE en los resultados del Software
							   objeto del presente contrato, ni por daños y perjuicios indirectos, incidentales, punitivos,
							   lucro cesante que se hayan producido al CLIENTE o a un tercero, considerándose como tales,
							   entre otros, la pérdida de oportunidades de negocio, costes por inactividad, pérdida de
							   datos, deterioro de la imagen del CLIENTE o pérdida de reputación comercial.</p>

							<h4>8. ADVERTENCIAS Y RESPONSABILIDADES</h4>
							<p>1. WeWelcom hará todo lo posible pero no garantiza
							   que el Software sea seguro o esté libre de defectos o virus. El Cliente es responsable de
							   configurar su tecnología de la información, programas informáticos y plataforma para acceder
							   y utilizar los Servicios. El Cliente debe utilizar su propio software de protección contra
							   virus.</p>
							<p>2. El Cliente no debe hacer un mal uso del Software introduciendo
							   intencionalmente virus, troyanos, gusanos, bombas lógicas u otro material malicioso o
							   tecnológicamente dañino. El Cliente no debe intentar obtener acceso no autorizado al
							   Software, a servidores de Wewelcom o a cualquier servidor, ordenador o base de datos
							   conectados al Software. El Cliente no debe atacar ningún aspecto del Software mediante un
							   ataque de denegación de servicio o un ataque de denegación de servicio distribuido. Wewelcom
							   reportará cualquier violación de este tipo a las autoridades pertinentes y cooperará con
							   dichas autoridades revelándoles la identidad del Cliente. En el caso de tal violación, el
							   derecho del Cliente a utilizar el software cesará inmediatamente.</p>

							<h4>9. DURACIÓN Y TERMINACIÓN</h4>
							<p>1. El presente contrato entrará y permanecerá en vigor en el momento de
							   firma del presente contrato, lo que supondrá la entrega de las indicaciones de acceso al
							   panel de la plataforma de gestión.</p>
							<p>2. La baja con anterioridad al cumplimiento del
							   período anual, exime a EL CLIENTE del pago de las mensualidades restantes siempre y cuando
							   lo haya notificado de forma fehaciente. No existe periodo mínimo de permanencia.</p>
							<p>3.
							   La renovación por períodos de igual duración (1 año) se producirá de forma automática si no
							   se comunica lo contrario a treinta días de finalización del mismo. En cada renovación puede
							   existir una revisión del contrato si así lo estiman alguna de las partes.</p>
							<p>4. La
							   activación del servicio se producirá de forma automática.</p>
							<p>5. El contrato se
							   extinguirá por las causas generales de extinción de los contratos, y en especial, por las
							   siguientes:</p>
							<p>Por voluntad unilateral de EL CLIENTE, que deberá preavisar a WEWELCOM con una antelación
							   mínima de treinta (30) días al momento en que haya de surtir efectos. Deberá ser
							   comunicada por medio fehaciente y, con anterioridad, EL CLIENTE deberá abonar todas las
							   facturas pendientes, sin perjuicio de la permanencia acordada en caso particular.</p>
							<p>Por incumplimiento grave de las obligaciones contractuales de las partes.</p>
							<p>Por uso ilícito del Servicio o uso contrario a la buena fe o las prácticas comúnmente
							   aceptadas como de correcta utilización de los servicios de SAAS por parte de EL
							   CLIENTE.</p>
							<p>Por retraso en el pago durante un periodo superior a tres (3) meses o la suspensión
							   temporal del contrato en dos (2) ocasiones por mora en el pago. Wewelcom restablecerá el
							   Servicio, en caso de suspensión por impago, dentro del día laborable siguiente a aquél
							   en que tenga constancia de que las cantidades adeudadas han sido abonadas por EL
							   CLIENTE.</p>
							<p>La resolución del contrato no eximirá a EL CLIENTE de sus obligaciones frente a Wewelcom,
							   incluida la obligación de pago.</p>
							<p>En caso de extinción de este contrato, WEWELCOM pondrá a su disposición un archivo con
							   los Datos del cliente durante un período de treinta (30) días. WEWELCOM se reserva el
							   derecho de eliminar y/o desechar los Datos del cliente sin previo aviso por motivo de su
							   incumplimiento, dentro del que se incluye, aunque no de forma exclusiva, su impago.</p>

							<h4>10. MODIFICACIONES CONTRACTUALES</h4>
							<p>WEWELCOM podrá realizar modificaciones al presente contrato notificándolo a EL CLIENTE
							   con una antelación mínima de un (1) mes a su entrada en vigor. En caso de que EL CLIENTE
							   no aceptase las nuevas condiciones y así lo notificase a WEWELCOM, podrá resolver el
							   contrato de manera anticipada y sin penalización alguna. Si, transcurrido el plazo de un
							   (1) mes desde la notificación por WEWELCOM, el Cliente no hubiese manifestado
							   expresamente su disconformidad, o bien EL CLIENTE utilizará el Servicio con
							   posterioridad a la entrada en vigor de la modificación anunciada, se entenderá que
							   acepta las modificaciones propuestas.</p>
							<h4>11. CESIÓN, CAMBIO DE CONTROL</h4>
							<p>EL CLIENTE no podrá ceder este Contrato sin el consentimiento previo por escrito de
							   WEWELCOM pero WEWELCOM sí podrá cederlo sin su consentimiento a (i) una organización
							   principal o filial, ii) un inversor mayoritario o (iii) un sucesor por fusión. Queda
							   anulada cualquier pretensión de cesión que infrinja lo estipulado en esta sección.
							   Cualquier cambio de control real o propuesto que dé como resultado que un competidor
							   directo de WEWELCOM posea o controle directa o indirectamente un mínimo del cincuenta
							   por ciento (50 %) de EL CLIENTE, permitirá a WEWELCOM resolver este Contrato por motivos
							   justificados de forma inmediata con aviso por escrito.</p>

							<h4>12. MISCELÁNEA</h4>
							<p>1.
							   En caso de no ser válida alguna de las disposiciones de este contrato, las partes acuerdan
							   que dicha invalidez no afectará a la validez y eficacia del resto de disposiciones del
							   presente contrato. Este documento representa la totalidad del contrato entre EL CLIENTE y
							   WEWELCOM el cual sustituye a cualquier contrato precedente, verbal o escrito.</p>
							<p>2. El
							   presente Contrato así como todos aquellos documentos que formen parte integrante del mismo,
							   podrán firmarse digitalmente, por una o por ambas partes, reconociendo a dicha firma digital
							   equivalente valor jurídico que a la firma manuscrita y, en consecuencia, quedando las partes
							   obligadas al cumplimiento de sus términos y condiciones.</p>
							<p>3. Con la firma del
							   presente Contrato EL CLIENTE autoriza a Wewelcom para la inclusión con fines promocionales
							   del logo, signos distintivos o marca del cliente.</p>

							<h4>13. TRATAMIENTO DE DATOS DE CARÁCTER PERSONAL</h4>
							<p>1. En caso de que, como consecuencia de la ejecución del presente
							   Contrato, WEWELCOM tenga acceso a datos de carácter personal de los que sea titular EL
							   CLIENTE (en adelante, los “Datos”), será de aplicación lo dispuesto en la Ley Orgánica
							   3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos
							   digitales y en el Reglamento General Europeo de Protección de Datos Personales. En este
							   sentido, WEWELCOM se obliga a tratar los Datos con absoluta confidencialidad y de acuerdo
							   con las instrucciones que reciba del Cliente, no pudiendo comunicarlo a terceros bajo ningún
							   concepto, ni utilizarlos con otro fin que el del cumplimiento estricto de las obligaciones
							   contenidas en el presente Contrato.</p>
							<p>2. WEWELCOM adoptará las medidas técnicas y
							   organizativas necesarias para garantizar la seguridad, confidencialidad e integridad de los
							   Datos de EL CLIENTE a los que tenga acceso en virtud del presente Contrato, con objeto de
							   evitar su alteración, pérdida o acceso o tratamiento no autorizado por parte de terceros de
							   conformidad con la legislación vigente en cada momento. En cumplimiento de lo dispuesto en
							   el artículo 28.3 del RGPD, WEWELCOM, como encargado del tratamiento de los datos personales,
							   y EL CLIENTE, como responsable de los mismos, suscriben un contrato para el tratamiento de
							   datos</p>
							<p>personales por cuenta de terceros que establece los términos y condiciones esenciales del
							   tratamiento, y que se adjunta como Anexo I.</p>
							<p>3. WEWELCOM y EL CLIENTE velarán por
							   el cumplimiento de la normativa de protección de datos vigente en cada momento en todos los
							   ámbitos regulados por la misma, incluyendo aspectos como el establecimiento de un
							   procedimiento de notificación y gestión de incidencias, la previsión de mecanismos de
							   control de acceso a los datos, la gestión de soportes y la realización de copias de
							   seguridad.</p>
							<p>4. El personal de WEWELCOM que, con motivo de la prestación de los
							   Servicios previstos en este Contrato, pueda conocer o acceder a datos de carácter personal,
							   tendrá la obligación de guardar secreto y confidencialidad respecto a los mismos, de acuerdo
							   con la legislación vigente en materia de protección de datos personales.</p>
							<p>5. A
							   requerimiento del Cliente, WEWELCOM facilitará al mismo toda la información y actuaciones
							   necesarias para que aquella pueda comprobar el cumplimiento por parte de WEWELCOM de todos
							   los compromisos en materia de protección de datos referidos en la presente Cláusula.</p>
							<p>6. En caso de que el Contrato termine por cualquier motivo, WEWELCOM procederá, a
							   elección del Cliente, en el plazo de un (1) mes, a la devolución o a la destrucción de
							   cualquier soporte o documento en poder de Wewelcom en el que se incluya o conste algún
							   Dato, salvo aquellos que se deban mantener por obligación legal o responsabilidad
							   contractual resultante de la relación de las partes.</p>
							<h4>14. LEY APLICABLE Y JURISDICCIÓN</h4>
							<p>1. El presente Contrato se rige por el derecho español.</p>
							<p>2. Las
							   Partes acuerdan someter toda cuestión litigiosa derivada o relacionada con este Contrato a
							   los juzgados y tribunales de Madrid, con renuncia expresa a su fuero propio, si otro les
							   correspondiere.</p>
							<p>Y en prueba de conformidad con cuanto antecede, las Partes firman el presente Contrato
							   por duplicado y a un solo efecto en el lugar y fecha indicados en el encabezamiento.</p>
							<p>FIRMAS:</p>
							<p>D. Miguel Ángel Díaz Martín D.</p>
							<p>WeWelcom Cliente:</p>
							<p>Firma: Firma:</p>


							<h2>ANEXO I:</h2>
							<p>TRATAMIENTO DE DATOS PERSONALES POR CUENTA TERCEROS</p>
							<p>ESTIPULACIONES:</p><h4>1. OBJETO</h4>
							<p>El objeto de este anexo es establecer las condiciones en las que WEWELCOM va a tratar los
							   datos de carácter personal a los que tenga acceso, como consecuencia de los servicios de
							   WEcopilot y asociados a este, objeto de EL CONTRATO, y cuya titularidad ostenta EL
							   CLIENTE.</p>
							<p>De acuerdo con la legislación vigente en materia de protección de datos, el acceso de
							   WEWELCOM a los datos facilitados por EL CLIENTE, no será considerado comunicación de
							   datos, ocupando WEWELCOM la posición de encargado de tratamiento.</p>
							<p>Concreción de los tratamientos a realizar:</p>
							<p>RECOGIDA.</p>
							<p>REGISTRO.</p>
							<p>MODIFICACIÓN.</p>
							<p>CONSERVACIÓN.</p>
							<p>CONSULTA.</p>
							<p>LIMITACIÓN.</p>
							<p>SUPRESIÓN.</p>
							<p>DESTRUCCIÓN.</p>
							<p>CONSERVACIÓN.</p>
							<p>Si en el desarrollo del Contrato, fuese necesaria la revelación o tratamiento de datos de
							   carácter personal entre ambas partes, dicha revelación se entenderá comprendida dentro
							   del marco recogido. Asimismo, y de conformidad con la legislación vigente en materia de
							   protección de datos de carácter personal y garantía de</p>
							<p>los derechos digitales, las partes garantizan cumplir con el deber de información con
							   respecto a sus empleados cuyos datos personales sean comunicados entre las partes para
							   el mantenimiento y cumplimiento de la relación contractual.</p><h4>2. DURACIÓN</h4>
							<p>El presente anexo entrará en vigor en la fecha de la firma del Contrato y durará hasta
							   que se produzca la terminación, por cualquier causa, del Contrato.</p>
							<p>Los datos serán tratados durante la vigencia de la relación contractual con esa base de
							   legitimación y, una vez concluida la misma, el Encargado del Tratamiento deberá
							   suprimir, y/o devolver al Responsable del Tratamiento, y/o devolver a otro encargado que
							   designe el Responsable del Tratamiento, los datos personales y eliminar cualquier copia
							   que esté en su poder. Exceptuando aquellos que deban quedar bloqueados hasta que se
							   produzca la extinción de cualquier responsabilidad legal que hubiera podido nacer de la
							   relación contractual entre las partes.</p><h4>3. TITULARIDAD DE LOS DATOS</h4>
							<p>EL CLIENTE proporcionará los datos que pasarán a formar parte de la base de datos de
							   WEWELCOM. EL CLIENTE mantendrá en todo momento su posición de Responsable del
							   Tratamiento de los datos y será el titular de la base de datos de estos, con las
							   modificaciones que sufran como consecuencia de su tratamiento.</p>
							<p>Así, en todo momento EL CLIENTE conservará el derecho exclusivo de propiedad y uso de los
							   datos, y todos sus procesos de actualización.</p><h4>4. OBLIGACIONES DEL ENCARGADO DEL
																					TRATAMIENTO, WEWELCOM:</h4>
							<p>WEWELCOM, en relación con los datos de carácter personal a los que tenga acceso como
							   consecuencia de la prestación de servicios objeto del Contrato, se obliga a:</p>
							<p>A. Tratar los datos personales únicamente conforme a las instrucciones documentadas del
							   responsable, inclusive con respecto a las transferencias de datos personales a un tercer
							   país o una organización internacional, salvo que esté obligado a ello en virtud del
							   Derecho de la Unión o de los Estados miembros que se aplique al encargado; en tal caso,
							   WEWELCOM informará a EL CLIENTE de esa exigencia legal previa al tratamiento, salvo que
							   tal Derecho lo prohíba por razones importantes de interés público;</p>
							<p>B. Garantizar que las personas autorizadas para tratar datos personales se hayan
							   comprometido a respetar la confidencialidad o estén sujetas a una obligación de
							   confidencialidad de naturaleza estatutaria;</p>
							<p>C. Tomar todas las medidas de seguridad necesarias de conformidad con lo establecido en
							   el RGPD;</p>
							<p>D. Respetar las condiciones indicadas en el RGPD para recurrir a otro encargado del
							   tratamiento. Se exige la autorización previa por escrito del Responsable del tratamiento
							   para que el Encargado del tratamiento pueda subcontratar con otro encargado
							   (Subencargado) la prestación de sus servicios, total o parcialmente, cuando esto
							   conlleve el tratamiento de los datos personales por parte de un tercero. Dicha
							   autorización podrá ser general o específica, en el caso de que se concrete la entidad
							   del Subencargado. El Subencargado del tratamiento estará sujeto a las mismas
							   condiciones, obligaciones y medidas de seguridad que el Encargado del tratamiento en lo
							   referente al adecuado tratamiento de los datos personales. En caso de incumplimiento por
							   el Subencargado, el Encargado inicial seguirá siendo plenamente responsable ante el
							   Responsable del Tratamiento en lo referente al cumplimiento de las obligaciones del
							   Subencargado;</p>
							<p>E. Asistir al responsable, teniendo cuenta la naturaleza del tratamiento, a través de
							   medidas técnicas y organizativas apropiadas, siempre que sea posible, para que este
							   pueda cumplir con su obligación de responder a las solicitudes que tengan por objeto el
							   ejercicio de los derechos de los interesados establecidos en el capítulo III del RGPD
							   (acceso, rectificación, supresión, oposición, limitación y portabilidad);</p>
							<p>F. Ayudar al responsable a garantizar el cumplimiento de las obligaciones establecidas en
							   la normativa de protección de datos aplicable, teniendo en cuenta la naturaleza del
							   tratamiento y la información a disposición del encargado;</p>
							<p>G. A elección del responsable, suprimirá o devolverá todos los datos personales una vez
							   finalice la prestación de los servicios del tratamiento, y suprimirá las copias
							   existentes a menos que se requiera la conservación de los datos personales en virtud del
							   Derecho de la Unión o de los Estados miembros o alguna obligación legal;</p>
							<p>H. Poner a disposición de EL CLIENTE toda la información necesaria para demostrar el
							   cumplimiento de las obligaciones establecidas en el presente artículo, así como para
							   permitir y contribuir a la realización de auditorías, incluidas inspecciones, por parte
							   del responsable o de otro auditor autorizado por dicho responsable.</p><h4>5.
																										  OBLIGACIONES DEL RESPONSABLE DEL TRATAMIENTO, EL CLIENTE:</h4>
							<p>Corresponde al responsable del tratamiento aplicar medidas que garanticen el cumplimiento
							   de la normativa vigente en relación a la protección de los datos de carácter personal y,
							   en relación con el encargado del tratamiento, se obliga a:</p>
							<p>A. Entregar al encargado los datos relativos a la información que quede afectada por el
							   tratamiento.</p>
							<p>B. Realizar una evaluación del impacto en la protección de datos personales de las
							   operaciones de tratamiento a realizar por el encargado.</p>
							<p>C. Realizar las consultas previas que corresponda.</p>
							<p>D. Velar, de forma previa y durante todo el tratamiento, por el cumplimiento del RGPD por
							   parte del encargado.</p>
							<p>E. Supervisar el tratamiento, incluida la realización de inspecciones y auditorías.</p>
							<h4>6. DEBER DE SECRETO</h4>
							<p>WEWELCOM queda obligado a mantener el más absoluto secreto sobre los datos que conozca
							   como consecuencia de la prestación del servicio, y se compromete a hacer cumplir el
							   mismo deber de secreto a aquellos de sus empleados que intervengan en cualquier fase del
							   tratamiento automatizado de datos de carácter personal, que se realice como consecuencia
							   de la prestación del servicio.</p><h4>7. CONSECUENCIAS DE LA TERMINACIÓN DEL
																	 CONTRATO</h4>
							<p>A la terminación del el Contrato, cualquiera que sea la causa, WEWELCOM, como hemos
							   expuesto en la CLÁUSULA SEGUNDA Y CUARTA G, deberá proceder a la destrucción o
							   devolución a EL CLIENTE de toda la información de carácter personal que haya sido objeto
							   de tratamiento como consecuencia de la prestación de servicio, así como cualquier tipo
							   de soporte o documento que contenga información de carácter personal, salvo que deba
							   mantenerla por obligación legal o responsabilidad contractual resultante de la relación
							   de las partes.</p><h4>8. IMCUMPLIMIENTO</h4>
							<p>En el caso de que alguna de las partes incumplan el presente anexo en algunos de sus
							   términos y/o la legislación vigente en materia de protección de datos de carácter
							   personal, responderá ante la Agencia de Protección de Datos de las infracciones que se
							   puedan haber cometido.</p><h4>9. NOTIFICACIONES</h4>
							<p>A efectos de notificaciones y requerimientos las partes señalan como domicilio los que
							   figuran en el encabezamiento de este documento.</p>
							<p>FIRMAS:</p>
							<p>D. Miguel Ángel Díaz Martín D. ______________________</p>
							<p>WeWelcom Cliente</p>
							<p>Firma: Firma:</p>


						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer text-end">
				<button type="button" class="btn btn-border btn-outline-primary " data-bs-dismiss="modal">Salir</button>
			</div>
		</div>
	</div>
</div>
<!-- END Modal Content PRIVACY -->


<FooterWewelcom/>
