<template src="./index.html"></template>

<script>

import AOS from 'aos';
import 'aos/dist/aos.css'
import NavbarWewelcom from '@/components/core/NavbarWewelcom'
import FooterWewelcom from "@/components/core/footerWewelcom/FooterWewelcom";

export default {
	name: "HomePage",
	data() {
		return {
			name: '',
			email: '',
			phone: '',
			city: '',
			businessName: '',
			signupTerms: false,
			businessType: 'Restaurantes',
			businessCount: '',
			employeeCount: '',
			showErrorMessageForm: false,
			showSuccessMessageForm: false,
			errorMessage: '',
			successMessage: '',
			questions: [],
			currentYear: new Date().getFullYear().toString(),
			clickedCta: null,
			comment: null,
			textButton: 'Enviar',
			textButtonSection: 'Únete aquí',
		};
	},
	inject: {
		formRepository: 'formRepository'
	},
	computed: {},
	components: {
		NavbarWewelcom,
		FooterWewelcom
	},
	beforeMount() {
		document.getElementsByTagName('body')[0].classList.add('bg-primary');
	},
	mounted() {
		console.log('Cargado componente formulario: ', this.user);
		this.scrollNavbar();

		AOS.init({
			// Opciones de inicialización, aquí puedes definir la duración, retrasos, etc.
			duration: 1200,
		});
	},
	watch: {},
	methods: {
		resetForm() {
			this.name = '';
			this.businessName = '';
			this.email = '';
			this.phone = '';
			this.city = '';
			this.businessType = 'Restaurantes';
			this.businessCount = '';
			this.employeeCount = '';
			this.questions = [];
			this.comment = null;
			this.showErrorMessageForm = false;
			this.signupTerms = false;
			this.errorMessage = "";
			this.clickedCta = null;
			this.clearCustomQuestions();
		},
		clearCustomQuestions() {
			const questionElements = document.querySelectorAll('.form-question-item');

			// Iterate over each question element to reset it
			questionElements.forEach((element) => {
				element.querySelector('.form-question-answer').value = "";
			});
		},
		setCta(clickedCTA) {
			//alert("Hemos pulsado "+ clickedCTA);
			this.clickedCta = clickedCTA;
			this.scrollToForm("landing-form");
		},
		scrollToForm(targetElement) {
			console.log("Haciendo scroll");
			const element = this.$refs[targetElement];
			if (element) {
				element.scrollIntoView({behavior: 'smooth'});
			}
		},
		setFormMessage(message) {
			if (message == null || message == "") {
				this.showErrorMessageForm = false;
				this.errorMessage = "";
			} else {
				this.showErrorMessageForm = true;
				this.errorMessage = message;
			}
		},
		async submitForm() {

			console.log("Enviando formulario")

			this.showErrorMessageForm = false;

			if (this.name === "") {
				this.setFormMessage("Por favor, introduce un nombre");
				this.errorMessage = 'Por favor, introduce un nombre';
				return false;
			}

			if (this.businessName === "") {
				this.setFormMessage("Por favor, introduce un nombre de compañía válida");
				this.errorMessage = "Por favor, introduce un nombre de compañía válida";
				return false;
			}

			if (this.email === "") {
				this.setFormMessage("Por favor, introduce un email válido");
				this.errorMessage = "Por favor, introduce un email válido";
				return false;
			}

			if (this.phone === "") {
				this.setFormMessage("Por favor, introduce un teléfono válido");
				this.errorMessage = "Por favor, introduce un teléfono válido";
				return false;
			}

			/*if (this.city === "") {
				this.setFormMessage("Por favor, introduce una ciudad válida");
				this.errorMessage = "Por favor, introduce una ciudad válida";
				return false;
			}*/

			if (!this.signupTerms) {
				this.setFormMessage("Es necesario aceptar la política de privacidad");
				this.errorMessage = "Es necesario aceptar la política de privacidad";
				return false;
			}

			// Find all question elements
			this.questions = [];

			// Find all question elements
			//const questionElements = document.querySelectorAll('.form-question-item');

			// Iterate over each question element to extract question and answer
			/*questionElements.forEach((element) => {
				const questionText = element.querySelector('.form-question-text').textContent;
				const answer = element.querySelector('.form-question-answer').value;

				// Push the extracted question and answer to the questions array
				this.questions.push({ question: questionText, answer: answer });
			});*/

			const formData = {
				name: this.name,
				businessName: this.businessName,
				email: this.email,
				phone: this.phone,
				city: this.city,
				businessType: this.businessType,
				businessCount: this.businessCount,
				employeeCount: this.employeeCount,
				questions: this.questions,
				comment: this.comment,
				clickedCta: this.clickedCta,
			};

			this.textButton = 'Enviando...'

			console.log(formData);

			let that = this;

			const formPostResult = await this.formRepository.sendHomePageFormData(formData);

			if (formPostResult.result === 0) {
				console.log('Form submitted successfully');
				this.showSuccessMessageForm = true;
				that.resetForm();
				this.successMessage = "Contacto registrado con éxito"
				this.textButton = 'Enviar'
				setTimeout(() => {
					this.showSuccessMessageForm = false;
				}, 20000)
			} else {
				console.error('Error submitting form');
				this.showErrorMessageForm = true;
				this.errorMessage = 'Algo ha salido mal, inténtalo más tarde';
				setTimeout(() => {
					this.showErrorMessageForm = false;
				}, 20000)
			}
		},

		scrollNavbar() {
			window.addEventListener('scroll', () => {
				let navbar = document.querySelector('#navbarTop');
				navbar.classList.toggle('scroll', window.scrollY > 60)
			})
		}
	},
}
</script>
