
export class FaviconService {

	constructor() {
	}

	changeFavicon(weWelcomUrl) {
		console.log("Podemos cambiar el favicon? "+weWelcomUrl)

		let src = "/world/img/default/favicons/wewelcom/favicon.ico";
		if (weWelcomUrl === "https://test.wewelcom.io"){
			src = "/world/img/default/favicons/wewelcom/favicon.ico";
		} else if (weWelcomUrl === "https://dev.wewelcom.io"){
			src = "/world/img/default/favicons/wewelcom/favicon.ico";
		}

		const link = document.querySelector('link[rel="icon"]') || document.createElement('link');
		link.type = 'image/png';
		link.rel = 'icon';
		link.href = src;
		document.getElementsByTagName('head')[0].appendChild(link);
	}

}

export const faviconService = new FaviconService();
