import {sendRequestJson} from '@/utils'

export class ConfigRepository {

	constructor() {

		let host = window.location.hostname;
		if (host === "localhost"){
			this.urlBase = 'https://devdemo.wewelcom.io';
		} else {
			this.urlBase = window.location.origin;
		}
	}

	async loadSystemConfig() {
		const url = this.urlBase + "/world/api/systemconfig"
		try {
			const configResult = await sendRequestJson({data:null, url, method:"GET"});
			if (configResult.result === 0){
				return configResult.systemConfig;
			} else {
				console.log("Error cargando configuración del sistema")
			}
		} catch (e) {
			console.log("Ups, algo ha ido mal obteniendo la configuración.");
		}
	}
}

export const configRepository = new ConfigRepository();
