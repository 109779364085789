<template src="./signup.html"></template>

<script>
import NavbarWewelcom from "@/components/core/NavbarWewelcom";
import FooterWewelcom from "@/components/core/footerWewelcom/FooterWewelcom";

export default {
	name: "SignupPage",
	data() {
		return {

			companyCommercialName: '',
			companyLegalName: '',
			companyFiscalId: '',
			companyWebsite: '',
			companyAddress: '',
			companyLocation: '',
			companyProvince: '',
			companyPostalCode: '',
			companyCountry: '',
			repName: '',
			repSurname: '',
			repFiscalId: '',
			repEmail: '',
			repPhone: '',
			proposedSubdomain: '',
			venueType: '',
			signupTerms: false,
			welcomTerms: false,
			restooTerms: false,
			showErrorMessageForm: false,
			showSuccessMessageForm: false,
			errorMessage: '',
			successMessage: '',
			questions: [],
			currentYear: new Date().getFullYear().toString(),
			subscriptionType: null,
			availableSubscriptionTypes: [
				{
					name: "Experience",
					price: 119,
					discountInitial: false,
					initialPromoFee: 119,
					highFee: true,
				},
				{
					name: "Pro",
					price: 199,
					discountInitial: true,
					initialPromoFee: 99,
					highFee: false,
				},
				{
					name: "Starter",
					price: 199,
					discountInitial: true,
					initialPromoFee: 99,
					highFee: false,
				},
				// {
				// 	name: "Business",
				// 	price: 299
				// },
				// {
				// 	name: "Concierge",
				// 	price: 499
				// }
			],
			availableHardware: {
				devices: [
					{
						name: "Tablets",
						priceBuy: 200,
						priceRent: 25,
						rentPeriod: 12
					},
					{
						name: "Móviles",
						priceBuy: 200,
						priceRent: 25,
						rentPeriod: 12
					},
					{
						name: "Pinganillos",
						priceBuy: 200,
						priceRent: 25,
						rentPeriod: 12
					}
				],
				apparel: [
					{
						name: "Soportes Mesa",
						priceBuy: 35,
						priceRent: 4.5,
						rentPeriod: 12
					},
					{
						name: "Soportes Pie",
						priceBuy: 100,
						priceRent: 12.5,
						rentPeriod: 12
					},
					{
						name: "Soportes Pie 2",
						priceBuy: 185,
						priceRent: 22.5,
						rentPeriod: 12
					}
				]
			},
			selectedDevices: [],
			selectedApparel: [],
			noDevicesNeeded: false,
			noApparelNeeded: false
		};
	},
	inject: {
		formRepository: 'formRepository'
	},
	computed: {
		clientSignupName(){
			if (this.subscriptionType === "Starter"){
				return "usuarios";
			} else {
				return "clientes";
			}
		},
		showForm(){
			return this.subscriptionType != null || this.subscriptionType === "Starter";
		},
		preRenderProposedSubdomain(){
			if (this.proposedSubdomain !== ''){
				return this.proposedSubdomain+".wewelcom.io";
			} else {
				return "?.wewelcom.io"
			}
		},
		subscription() {
			return this.$route.params.subscription.toUpperCase();
		},

	},
	components: {
		NavbarWewelcom,
		FooterWewelcom
	},
	beforeMount() {
		document.getElementsByTagName('body')[0].classList.add('bg-primary');
	},
	created() {
		const subscriptionMap = {
			experience: 'Experience',
			pro: 'Pro',
			business: 'Business',
			starter: 'Starter',
		};
		const subscription = this.$route.params.subscription;
		if (subscription && subscriptionMap[subscription]) {
			this.subscriptionType = subscriptionMap[subscription];
		}
	},
	mounted() {
		console.log('Cargado componente formulario: ', this.user);
		this.scrollNavbar()
	},
	watch: {
		'$route.params.subscription'(newSubscription) {
			const subscriptionMap = {
				experience: 'Experience',
				pro: 'Pro',
				business: 'Business',
				starter: 'Starter',
			};
			if (newSubscription && subscriptionMap[newSubscription]) {
				this.subscriptionType = subscriptionMap[newSubscription];
			}
		},
		companyCommercialName(){
			this.proposedSubdomain = this.companyCommercialName.replace(/\s/g, "").toLowerCase()
		},

	},
	methods: {

		onSelectHardware(item, type, value, mode) {
			let targetArray;
			if (type === 'device') {
				targetArray = this.selectedDevices;
			} else if (type === 'apparel') {
				targetArray = this.selectedApparel;
			}

			// Find the existing item
			let existingItem = targetArray.find(i => i.name === item.name);

			if (existingItem) {
				// Update the existing item
				if (mode === 'compra' || mode === 'alquiler') {
					existingItem.price = parseFloat(value);
					existingItem.mode = mode;
				} else if (mode === 'quantity') {
					existingItem.quantity = parseInt(value, 10);
				}
			} else {
				// Create a new item
				let newItem = {
					name: item.name,
					type: type,
					price: (mode === 'compra' || mode === 'alquiler') ? parseFloat(value) : 0,
					quantity: (mode === 'quantity') ? parseInt(value, 10) : 0,
					mode: (mode === 'compra' || mode === 'alquiler') ? mode : ''
				};
				targetArray.push(newItem);
			}
		},
		resetForm(){
			this.companyCommercialName = '';
			this.companyLegalName = '';
			this.companyFiscalId = '';
			this.companyWebsite = '';
			this.companyAddress = '';
			this.companyLocation = '';
			this.companyProvince = '';
			this.companyPostalCode = '';
			this.companyCountry = '';
			this.repName = '';
			this.repSurname = '';
			this.repEmail = '';
			this.repPhone = '';
			this.repFiscalId = '';
			this.venueType = '';
			if (this.subscriptionType !== `Starter`){
				this.subscriptionType = -1;
			}
			this.showErrorMessageForm = false;
			this.signupTerms = false;
			this.restooTerms = false;
			this.errorMessage = "";
		},

		subscriptionInitialDiscountFee(price){
			return price / 2;
		},
		selectSubscriptionType(subscriptionType){
			console.log("Pulsado "+ subscriptionType.name)
			this.subscriptionType = subscriptionType.name;
		},
		setFormMessage(message){
			if (message == null || message === ""){
				this.showErrorMessageForm = false;
				this.errorMessage = "";
			} else {
				this.showErrorMessageForm = true;
				this.errorMessage = message;
			}
		},
		async submitForm() {

			console.log("Enviando formulario")

			this.showErrorMessageForm = false;

			if (this.companyCommercialName === ""
				|| this.companyLegalName === ""
				|| this.companyFiscalId === ""
				|| this.companyAddress === ""
				|| this.companyLocation === ""
				|| this.companyProvince === ""
				|| this.companyPostalCode === ""
				|| this.companyCountry === ""
				|| this.repName === ""
				|| this.repSurname === ""
				|| this.repEmail === ""
				|| this.repPhone === ""
				|| this.repFiscalId === ""

			) {
				this.setFormMessage("Por favor, rellena todos los campos del formulario");
				return false;
			}

			/*if (this.venueType === "") {
				this.setFormMessage("Por favor, indica si el alta es para un restaurante o un hotel");
				return false;
			}*/

			if (!this.signupTerms) {
				this.setFormMessage("Es obligatorio aceptar la política de privacidad y el Contrato de suscripción de licencia");
				return false;
			}

			/*if (!this.restooTerms) {
				this.setFormMessage("Es necesario aceptar la el contrato de Restoo");
				return false;
			}*/

			if (this.noDevicesNeeded) {
				this.selectedDevices = [];
			}
			if (this.noApparelNeeded) {
				this.selectedApparel = [];
			}

			let subscriptionTypeString = this.subscriptionType === "Starter" ? "Pro" : this.subscriptionType;

			const formData = {
				companyCommercialName: this.companyCommercialName,
				companyLegalName: this.companyLegalName,
				companyFiscalId: this.companyFiscalId,
				companyWebsite: this.companyWebsite,
				companyAddress: this.companyAddress,
				companyLocation: this.companyLocation,
				companyProvince: this.companyProvince,
				companyPostalCode: this.companyPostalCode,
				companyCountry: this.companyCountry,
				repName: this.repName,
				repSurname: this.repSurname,
				repEmail: this.repEmail,
				repPhone: this.repPhone,
				repFiscalId: this.repFiscalId,
				venueType: this.venueType,
				subscriptionType: subscriptionTypeString,
				proposedSubdomain: this.proposedSubdomain,
				selectedDevices: this.selectedDevices,
				selectedApparel: this.selectedApparel,
			};

			console.log(formData);

			let that = this;

			const formPostResult = await this.formRepository.sendClientRegisterPageFormData(formData);

			if (formPostResult.result === 0) {
				console.log('Form submitted successfully');
				this.showSuccessMessageForm = true;

				let message = "Alta de cliente registrada, gracias. Nuestro equipo se pondrá en contacto contigo lo antes posible";

				if (this.subscriptionType === "Starter"){
					message = "¡Estupendo! Te hemos registrado, gracias. Nuestro equipo se pondrá en contacto contigo lo antes posible.";
				}
				this.successMessage = message;

				that.resetForm();

				setTimeout(function () {
					this.showSuccessMessageForm = false;
				}, 3000)
			} else {
				console.error('Error submitting form');
				this.showErrorMessageForm = true;
				this.errorMessage = 'Algo ha salido mal, inténtalo más tarde';
				setTimeout(function () {
					this.showErrorMessageForm = false;
				}, 3000)
			}
		},

		scrollNavbar () {
			window.addEventListener('scroll', function() {
				let navbar = document.querySelector('#navbarTop');
				navbar.classList.toggle('scroll', window.scrollY > 60)
			})
		}
	},
}
</script>

