<!--<!DOCTYPE html>-->
<!--<html>-->
<!--<head>-->
<!--	<title>WeCopilot Hospitality</title>-->
<!--	<link rel="stylesheet" href="https://unpkg.com/aos@next/dist/aos.css"/>-->
<!--</head>-->
<!--<body class="body-secondary">-->

<NavbarWewelcom/>

<section class="section-primary header-hero my-0">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-12">
				<div class="hero-content-img">
					<img src="/world/img/wewelcom/logo/logo.png" alt="" class="w-100">
				</div>
			</div>
		</div>
	</div>
</section>

<section class="section-primary header-hero mt-0">
	<div class="container hero-content">
		<div class="row">
			<div class="col-12">
				<p>
					Querido colega hostelero,
				</p>
			</div>
		</div>

		<div class="row">
			<div class="col-12">
				<p>
					Hace seis años, decidimos abrir nuestro restaurante con un objetivo claro:
					<b>ayudar a otros propietarios a vivir mucho mejor</b>.
				</p>
			</div>
		</div>

		<br>

		<div class="row">
			<div class="col-12">
				<p>
					Aunque nuestra pasión por los restaurantes y las personas era innegable, sabíamos que necesitábamos experimentar de primera mano los desafíos del negocio, para cumplir nuestra misión de hacerte sentir mejor.
				</p>
			</div>
		</div>

		<br>

		<div class="row">
			<div class="col-12">
				<p>
					Hoy podemos compartir contigo que también somos hosteleros, además de ingenieros, y te aseguramos que nuestra admiración hacia ti no tiene límites.
				</p>
			</div>
		</div>

		<br>

		<div class="row">
			<div class="col-12">
				<p>
					<b>
						¿Lo primero que aprendimos en este viaje?
					</b>
				</p>
			</div>
		</div>

		<br>


		<div class="row">
			<div class="col-12">
				<p>
					Que este camino es verdaderamente duro y que solo aquellos que se dedican a él son auténticos héroes.
				</p>
			</div>
		</div>

		<br>

		<div class="row">
			<div class="col-12">
				<p>
					También descubrimos que definitivamente
					<b>
						todo gira en torno a las personas:
						<br>
						Los trabajadores que dan vida a nuestro restaurante y los consumidores que confían en nosotros para ofrecerles una experiencia satisfactoria.
					</b>
				</p>
			</div>
		</div>

		<br>

		<div class="row">
			<div class="col-12 ">
				<p>
					Lo que más nos quitaba el sueño eran los desafíos relacionados con el personal:
				</p>

				<ul>
					<li>
						<p>
							Las tensiones entre profesionales y no profesionales.
						</p>
					</li>
					<li>
						<p>
							Los conflictos entre sala y cocina.
						</p>
					</li>
					<li>
						<p>
							Las diferencias entre los recién llegados y los veteranos.
						</p>
					</li>
				</ul>
			</div>
		</div>

		<br>


		<div class="row">
			<col-12>
				<p>
					Todo esto afectaba directamente a los clientes y por supuesto, a nosotros, los propietarios.
					<br>
					<b>Vivíamos constantemente intranquilos.</b>
				</p>
			</col-12>
		</div>

		<br>

		<div class="row">
			<div class="col-12">
				<p>
					Así que esto <b>fue lo primero que solucionamos con nuestra tecnología:</b>
				</p>
			</div>

			<div class="col-12">
				<ul>
					<li>
						<p>
							La alta rotación.
						</p>
					</li>
					<li>
						<p>
							El exceso de personal contratado “por si acaso”
						</p>
					</li>
					<li>
						<p>
							Y las disputas entre el equipo.
						</p>
					</li>
				</ul>
			</div>
		</div>

		<br>

		<div class="row">
			<div class="col-12">
				<p>
					No solo conseguimos más margen, sino la tan deseada tranquilidad.
				</p>
			</div>
		</div>
		<br>

		<div class="row">
			<div class="col-12">
				<p>
					Ya lo estamos probando sin compromiso con otros restaurantes y hoteles por si te interesa darnos tu opinión:
				</p>
			</div>
		</div>
	</div>
</section>

<section>
	<div class="container">
		<div data-aos="zoom-in-up" data-aos-anchor-placement="center-bottom">
			<div class="row row-column-gap justify-content-center">
				<div class="col-md-6">
					<div class="input-group">
						<span class="input-group-text"><i class="fas fa-user"></i></span>
						<input v-model="name" type="text" class="form-control" placeholder="Nombre*">
					</div>
				</div>

				<div class="col-auto">
					<button class="btn btn-primary" @click="setCta(1)">{{ textButtonSection }}</button>

				</div>
			</div>
		</div>
	</div>
</section>

<section class="section-primary header-hero">
	<div class="container hero-content">
		<div class="row">
			<div class="col-12">
				<p>
					¡Así sí! Fue lo primero que nos vino a la cabeza al “profesionalizar” las cuentas y al equipo. Ahora teníamos tiempo y la oportunidad de mimar a nuestros clientes como se merecen.
				</p>
			</div>
		</div>
		<br>

		<div class="row">
			<div class="col-12">
				<p>
					Aquí como informáticos, no entendíamos que no se conociese a todos los clientes que entraban por la puerta. Un negocio de tanta cercanía, y parecía que fuese de desconocidos.
				</p>
			</div>
		</div>
		<br>

		<div class="row">
			<div class="col-12">
				<p>
					Luego nos enteramos de que por eso muchos propietarios estaban en el negocio, para ser ellos los que saludaran a su clientela fiel.
					<br>
					O peor, dependían de un trabajador de toda la vida que podía irse en cualquier momento.
				</p>
			</div>
		</div>
		<br>

		<div class="row">
			<div class="col-12">
				<p>
					<b>
						Así que nos pusimos, y conseguimos tratar a todos los consumidores por su nombre, independientemente de quien estuviera trabajando o el tiempo que llevase.
					</b>
				</p>
			</div>
		</div>
		<br>
		<div class="row">
			<div class="col-12">
				<p>
					Al final, con menos trabajadores, pero más profesionales y con más tiempo para centrarse en el consumidor, fue la clave. Y a los clientes les gustaba, volvían más y gastaban más… a lo que a nuestro parecer era más que lógico.
				</p>
			</div>
		</div>
		<br>
		<div class="row">
			<div class="col-12">
				<p>
					Si esto sí te interesa, ya sabes, pruébalo ahora que estamos ofreciéndolo sin compromiso:
				</p>
			</div>
		</div>
	</div>
</section>

<section class="header-hero header-hero-secondary">
	<div data-aos="zoom-in-up" data-aos-anchor-placement="center-bottom" class="container">
		<div class="row row-column-gap justify-content-center">
			<div class="col-md-6">
				<div class="input-group">
					<span class="input-group-text"><i class="fas fa-user"></i></span>
					<input v-model="name" type="text" class="form-control" placeholder="Nombre*">
				</div>
			</div>

			<div class="col-auto">
				<button class="btn btn-primary" @click="setCta(2)">{{ textButtonSection }}</button>

			</div>
		</div>
	</div>
</section>

<section class="section-primary header-hero">
	<div class="container hero-content">
		<div class="row">
			<div class="col-12">
				<p>
					Porque como dijo nuestro encargado de marketing:
				</p>
			</div>
		</div>
		<br>

		<div class="row">
			<div class="col-12">
				<p>
					Nos convertimos en embajadores de nuestra marca, creando experiencias inolvidables.
					<br>
					<b>Tejemos relaciones, vendemos con pasión y recomendamos con autenticidad.</b>
					<br>
					Cada interacción es una oportunidad para hacer brillar nuestro restaurante y dejar una huella duradera en quienes nos visitan.
				</p>
			</div>
		</div>
		<br>

		<div class="row">
			<div class="col-12">
				<p>
					Que no te preocupe el precio, ya que después de probarlo, tendrás varias opciones que no superarán los 200€/mes. Y
					<b> a nosotros nos hizo ganar 20 veces más.</b>
				</p>
			</div>
		</div>
	</div>
</section>

<!--<section>-->
<!--	<div class="row justify-content-md-center">-->
<!--		<div class="col-md-6">-->
<!--			<div class="input-group">-->
<!--				<span class="input-group-text"><i class="fas fa-user"></i></span>-->
<!--				<input v-model="name" type="text" class="form-control" placeholder="Nombre*">-->
<!--			</div>-->
<!--		</div>-->
<!--	</div>-->
<!--	<div class="content-center">-->
<!--		<button class="btn btn-primary" @click="setCta(3)">Únete aquí</button>-->
<!--	</div>-->
<!--</section>-->

<section class="section-primary header-hero">
	<div class="container hero-content">
		<div class="row">
			<div class="col-12 text-center">
				<p>
					<b>
						¿Te apetece unirte a la nueva hostelería?
					</b>
				</p>
			</div>
		</div>

		<form ref="landing-form" id="landing-form" @submit.prevent="submitForm">
			<div class="row row-column-gap row-margin-x">
				<div class="col-12 col-md-6">
					<div class="input-group">
						<span class="input-group-text"><i class="fas fa-user"></i></span>
						<input id="name-lead" v-model="name" type="text" class="form-control" placeholder="Nombre*">
					</div>
				</div>
				<div class="col-12 col-md-6">
					<div class="input-group"><span class="input-group-text"><i class="fas fa-building"></i></span>
						<input id="company-lead" v-model="businessName" type="text" class="form-control" placeholder="Nombre Restaurante/Hotel*">
					</div>
				</div>
				<div class="col-12 col-md-6">
					<div class="input-group"><span class="input-group-text"><i class="fas fa-envelope"></i></span>
						<input id="email-lead" v-model="email" type="email" class="form-control" placeholder="Email*">
					</div>
				</div>
				<div class="col-12 col-md-6">
					<div class="input-group"><span class="input-group-text"><i class="fas fa-phone"></i></span>
						<input id="phone-lead" v-model="phone" type="tel" class="form-control" placeholder="Teléfono*">
					</div>
				</div>
				<div class="col-12">
					<div class="form-floating">
						<textarea v-model="comment" class="form-control" placeholder="Comentario" id="floatingTextarea"></textarea>
						<label for="floatingTextarea">Si deseas contarnos lo que más te preocupa de tu negocio:</label>
					</div>
				</div>
			</div>

			<div class="row justify-content-center">
				<div class="col-auto">
					<div id="signupTermsSection" class="form-check text-white">
						<input id="signup-terms" class="form-check-input" v-model="signupTerms" type="checkbox" value="signup-terms">
						<label class="form-check-label" for="signup-terms"> Acepto la política de privacidad</label> (<a data-bs-toggle="modal" data-bs-target="#privacy-terms-modal" class="f-no-utm" href="#termsPrivacy">ver</a>)
					</div>
				</div>
			</div>

			<div :class="{ 'hidden': !showErrorMessageForm }" class="row form-row-content">
				<div class="alert alert-danger" role="alert">
					{{ errorMessage }}
				</div>
			</div>

			<div id="reservationResponseCollapse" :class="{ 'hidden': !showSuccessMessageForm }"
				 class="alert alert-success" role="alert">
				{{ successMessage }}
			</div>

			<div class="content-center">
				<button id="submitFormButton" @click:prevent="submitForm" class="btn btn-secondary text-uppercase" style="width: 10rem">
					{{ textButton }}
					<span v-if="textButton === 'Enviando...'">
					<i class="fas fa-spinner fa-spin"></i>
				</span>
				</button>
			</div>

		</form>
	</div>
</section>

<section class="header-hero">
	<div class="container hero-content">
		<!--		<div class="row">-->
		<!--			<div class="col">-->
		<!--				<p>-->
		<!--					Seguimos adelante con la esperanza de encontrar soluciones y hacer de nuestro restaurante un lugar donde todos, <b>desde los empleados hasta nuestros queridos clientes, puedan sentirse verdaderamente valorados y bienvenidos.</b>-->
		<!--				</p>-->
		<!--			</div>-->
		<!--		</div>-->

		<!--		<br>-->

		<div class="row">
			<div class="col">
				<p>
					Con todo nuestro afecto,
				</p>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<p>
					El equipo de WeWelcom.
				</p>
			</div>
		</div>
	</div>
</section>

<!--<section class="section-hero-achievement">-->
<!--	<div class="container">-->
<!--		<div class="row row-column-gap">-->
<!--			<div class="col-lg-8 col-xl-8">-->
<!--				<div class="text-achievement">"Recibimos a <span> 11.745 personas en un mes </span> gracias a WEwelcom.<br>-->
<!--											  Que los clientes sientan la calidez humana es lo que marca la diferencia."-->
<!--				</div>-->
<!--				<div class="text-content-name">-->
<!--					<h4>Marta Pujol • Marketing and Communications Manager</h4>-->
<!--					<p>HOTEL MANDARIN ORIENTAL</p>-->
<!--				</div>-->
<!--			</div>-->
<!--			<div class="col-lg-4 col-xl-4 img-content-achievement">-->
<!--				<img src="assets/img/mandarin-white-v.png" class="d-block img-fluid" alt="...">-->
<!--			</div>-->
<!--		</div>-->
<!--	</div>-->
<!--</section>-->

<!--<div id="wewelcom-landing-section">-->

<!--	<div class="bg-primary pb-5 pb-lg-0 pt-5 pt-lg-0 text-secondary">-->
<!--		<div class="container pb-5 pb-lg-0 pt-lg-0">-->
<!--			<div class="align-items-center py-lg-5 row">-->
<!--				<div class="col-lg-6 mb-5 mb-lg-0">-->
<!--					<p class="d-flex justify-content-center justify-content-lg-start">-->
<!--						<img src="/assets/img/logo/logo.png" alt="" class="w-100" style="max-width: 450px">-->
<!--					</p>-->
<!--					<h1 class="ms-md-4 mb-4 pb-3 fw-normal text-center text-lg-start text-light text-uppercase border-bottom border-5" style="font-size: 1.3rem !important;">-->
<!--						La <span class="fw-bold">alta rotación de personal</span> en restaurantes afecta la atención al cliente y el negocio.-->
<!--					</h1>-->
<!--					<h2 class="ms-md-4 mb-4 pb-3 fw-normal text-center text-lg-start text-light border-bottom-0 lh-sm" style="font-size: 2rem !important;">-->
<!--						Con <span class="fw-bold text-uppercase">WECOPILOT</span> de <span class="fw-bold text-uppercase">WEWELCOM</span>  podrás procesar toda la <span class="fw-bold">información</span> en tiempo real para mejorar la <span class="fw-bold">eficiencia</span> del personal, la <span class="fw-bold">experiencia</span> del cliente y la <span class="fw-bold">rentabilidad</span> del negocio.-->
<!--					</h2>-->
<!--				</div>-->
<!--				<div class="col-lg-6 d-flex justify-content-end align-items-center">-->
<!--					<img src="/assets/img/mocks/header.png" class="img-fluid" alt="Mockup de móvil con el fondo de la aplicación." style="mask-image: linear-gradient(black 90%, transparent)"/>-->
<!--				</div>-->
<!--			</div>-->
<!--		</div>-->
<!--	</div>-->

<!--	<section class="text-center text-md-start text-white my-5 rounded-0 p-4" style="background-color: #d7ad65">-->
<!--		<div class="container">-->
<!--			<div class="align-items-center row">-->
<!--				<div class="col-lg-8 col-xl-8 mt-md-0 order-md-2 pb-5 pb-md-0 ps-md-4 pe-md-0 pt-5 pt-md-0">-->
<!--					<h3 class="h3 mb-4 text-white ps-md-5" style="font-size: 1.8rem;">"Recibimos a <span class="text-white fw-bold"> 11.745 personas en un mes </span> gracias a WEwelcom.<br>-->
<!--						Que los clientes sientan la calidez humana es lo que marca la diferencia."</h3>-->
<!--					<div class="text-end pe-md-5">-->
<!--						<h4 class="h6 fs-5">Marta Pujol • Marketing and Communications Manager</h4>-->
<!--						<p class="h6 fs-5 text-white fw-bold">HOTEL MANDARIN ORIENTAL</p>-->
<!--					</div>-->
<!--				</div>-->
<!--				<div class="col-lg-4 col-xl-4 order-md-1 p-0 align-items-center d-flex justify-content-center justify-content-lg-start ps-md-4">-->
<!--					<img src="assets/img/mandarin-white-v.png" class="d-block img-fluid" alt="..." style="max-width: 150px; width: auto">-->
<!--				</div>-->
<!--			</div>-->
<!--		</div>-->
<!--	</section>-->
<!--	&lt;!&ndash; Formulario &ndash;&gt;-->
<!--</div>-->
<!--<form id="landing-form" @submit.prevent="submitForm">-->
<!--	<section class="bg-primary my-3 rounded-0 p-4">-->
<!--		<div class="container">-->
<!--			<h2 class="mb-4 text-light text-center border-bottom-0">¿Quieres marcar la diferencia? Asegura tu Lugar en la Lista de Espera!</h2>-->

<!--			<div class="row mb-3 px-md-4">-->
<!--				<div class="col-12 col-md-4 mb-4">-->
<!--					<div class="input-group">-->
<!--						<span class="input-group-text"><i class="fas fa-user"></i></span>-->
<!--						<input id="name-lead" v-model="name" type="text" class="form-control" placeholder="Nombre*">-->
<!--					</div>-->
<!--				</div>-->
<!--				<div class="col-12 col-md-4 mb-4">-->
<!--					<div class="input-group"><span class="input-group-text"><i class="fas fa-building"></i></span>-->
<!--						<input id="company-lead" v-model="businessName" type="text" class="form-control" placeholder="Compañía*">-->
<!--					</div>-->
<!--				</div>-->
<!--				<div class="col-12 col-md-4 mb-4">-->
<!--					<div class="input-group"><span class="input-group-text"><i class="fas fa-envelope"></i></span>-->
<!--						<input id="email-lead" v-model="email" type="email" class="form-control" placeholder="Email*">-->
<!--					</div>-->
<!--				</div>-->
<!--				<div class="col-12">-->
<!--					<div class="row justify-content-center">-->
<!--						<div class="col-12 col-md-4 mb-3">-->
<!--							<div class="input-group"><span class="input-group-text"><i class="fas fa-phone"></i></span>-->
<!--								<input id="phone-lead" v-model="phone" type="tel" class="form-control" placeholder="Teléfono*">-->
<!--							</div>-->
<!--						</div>-->
<!--						<div class="col-12 col-md-4 mb-3">-->
<!--							<div class="input-group"><span class="input-group-text"><i class="fas fa-city"></i></span>-->
<!--								<input id="city-lead" v-model="city" type="text" class="form-control" placeholder="Ciudad*">-->
<!--							</div>-->
<!--						</div>-->
<!--					</div>-->
<!--				</div>-->
<!--			</div>-->
<!--			&lt;!&ndash;		<div class="row mb-3">&ndash;&gt;-->
<!--			&lt;!&ndash;			<div class="col-12 col-md-6 mb-2 ">&ndash;&gt;-->
<!--			&lt;!&ndash;				<div class="input-group">&ndash;&gt;-->
<!--			&lt;!&ndash;					<span class="input-group-text"><i class="fas fa-user"></i></span>&ndash;&gt;-->
<!--			&lt;!&ndash;					<input id="name-lead" v-model="name" type="text" class="form-control" placeholder="Nombre*">&ndash;&gt;-->
<!--			&lt;!&ndash;				</div>&ndash;&gt;-->
<!--			&lt;!&ndash;			</div>&ndash;&gt;-->
<!--			&lt;!&ndash;			<div class="col-12 col-md-6 mb-2">&ndash;&gt;-->
<!--			&lt;!&ndash;				<div class="input-group"><span class="input-group-text"><i class="fas fa-building"></i></span>&ndash;&gt;-->
<!--			&lt;!&ndash;					<input id="company-lead" v-model="businessName" type="text" class="form-control" placeholder="Compañía*">&ndash;&gt;-->
<!--			&lt;!&ndash;				</div>&ndash;&gt;-->
<!--			&lt;!&ndash;			</div>&ndash;&gt;-->
<!--			&lt;!&ndash;			<div class="col-12 col-md-6 mb-2">&ndash;&gt;-->
<!--			&lt;!&ndash;				<div class="input-group"><span class="input-group-text"><i class="fas fa-envelope"></i></span>&ndash;&gt;-->
<!--			&lt;!&ndash;					<input id="email-lead" v-model="email" type="email" class="form-control" placeholder="Email*">&ndash;&gt;-->
<!--			&lt;!&ndash;				</div>&ndash;&gt;-->
<!--			&lt;!&ndash;			</div>&ndash;&gt;-->
<!--			&lt;!&ndash;			<div class="col-12 col-md-6 mb-2">&ndash;&gt;-->
<!--			&lt;!&ndash;				<div class="input-group"><span class="input-group-text"><i class="fas fa-phone"></i></span>&ndash;&gt;-->
<!--			&lt;!&ndash;					<input id="phone-lead" v-model="phone" type="tel" class="form-control" placeholder="Teléfono*">&ndash;&gt;-->
<!--			&lt;!&ndash;				</div>&ndash;&gt;-->
<!--			&lt;!&ndash;			</div>&ndash;&gt;-->
<!--			&lt;!&ndash;			<div class="col-12 col-md-6 mb-2">&ndash;&gt;-->
<!--			&lt;!&ndash;				<div class="input-group"><span class="input-group-text"><i class="fas fa-city"></i></span>&ndash;&gt;-->
<!--			&lt;!&ndash;					<input id="city-lead" v-model="city" type="text" class="form-control" placeholder="Ciudad">&ndash;&gt;-->
<!--			&lt;!&ndash;				</div>&ndash;&gt;-->
<!--			&lt;!&ndash;			</div>&ndash;&gt;-->
<!--			&lt;!&ndash;		</div>&ndash;&gt;-->
<!--			<div class="row justify-content-center mb-3">-->
<!--				<div class="col-auto">-->
<!--					<div id="signupTermsSection" class="form-check text-white">-->
<!--						<input id="signup-terms" class="form-check-input" v-model="signupTerms" type="checkbox" value="signup-terms">-->
<!--						<label class="form-check-label" for="signup-terms"> Acepto la política de privacidad</label> (<a data-bs-toggle="modal" data-bs-target="#privacy-terms-modal" class="f-no-utm" href="#termsPrivacy">ver</a>)-->
<!--					</div>-->
<!--				</div>-->
<!--			</div>-->

<!--			<div :class="{ 'hidden': !showErrorMessageForm }"-->
<!--				 class="alert alert-danger" role="alert">-->
<!--				{{errorMessage}}-->
<!--			</div>-->

<!--			<div id="reservationResponseCollapse" :class="{ 'hidden': !showSuccessMessageForm }"-->
<!--				 class="alert alert-success" role="alert">-->
<!--				{{successMessage}}-->
<!--			</div>-->

<!--			<section v-if="false" class="in-section m-0 mb-2 p-0 border-0">-->
<!--				<div class="container">-->
<!--					<div class="row ">-->
<!--						<div class="col">-->
<!--							<a class="in-button-collapse collapsed" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample"> Más información </a>-->
<!--						</div>-->
<!--					</div>-->
<!--					<div class="row">-->
<!--						<div class="collapse " id="collapseExample">-->
<!--							<div class="container">-->
<!--								<div class="row align-items-end">-->
<!--									<p class="h4">Personal</p>-->
<!--									<div class="col-12 col-md-6 mb-2 form-question-item">-->
<!--										<label for="name-lead" class="form-question-text">¿Utilizas algún software o aplicación para gestionar las reservas de tu establecimiento?</label>-->
<!--										<div class="input-group"><span class="input-group-text"><i class="fas fa-file-alt"></i></span>-->
<!--											<input type="text" class="form-control form-question-answer" placeholder="">-->
<!--										</div>-->
<!--									</div>-->
<!--									<div class="col-12 col-md-6 mb-2 form-question-item">-->
<!--										<label for="name-lead" class="form-question-text">¿Cómo gestionas los pedidos en tu establecimiento?</label>-->
<!--										<div class="input-group"><span class="input-group-text"><i class="fas fa-file-alt"></i></span>-->
<!--											<input type="text" class="form-control form-question-answer" placeholder="">-->
<!--										</div>-->
<!--									</div>-->
<!--								</div>-->
<!--								<div class="row align-items-end">-->
<!--									<p class="h4">Restaurante</p>-->
<!--									<div class="col-12 col-md-6 mb-2 form-question-item">-->
<!--										<label for="name-lead" class="form-question-text">¿Qué áreas específicas de gestión te gustaría mejorar en tu negocio? (Por ejemplo: gestión de inventario, control de personal, análisis de datos)</label>-->
<!--										<div class="input-group"><span class="input-group-text"><i class="fas fa-file-alt"></i></span>-->
<!--											<input id="" type="text" class="form-control form-question-answer" placeholder="">-->
<!--										</div>-->
<!--									</div>-->
<!--									<div class="col-12 col-md-6 mb-2 form-question-item">-->
<!--										<label for="name-lead" class="form-question-text">¿Cuál es tu presupuesto aproximado para implementar soluciones de gestión en tu restaurante?</label>-->
<!--										<div class="input-group"><span class="input-group-text"><i class="fas fa-file-alt"></i></span>-->
<!--											<input id="" type="text" class="form-control form-question-answer" placeholder="">-->
<!--										</div>-->
<!--									</div>-->
<!--									<div class="col-12 col-md-6 mb-2 form-question-item">-->
<!--										<label for="name-lead" class="form-question-text">¿Cuáles consideras que son las principales fortalezas de tu negocio?</label>-->
<!--										<div class="input-group"><span class="input-group-text"><i class="fas fa-file-alt"></i></span>-->
<!--											<input id="" type="text" class="form-control form-question-answer" placeholder="">-->
<!--										</div>-->
<!--									</div>-->
<!--									<div class="col-12 col-md-6 mb-2 form-question-item">-->
<!--										<label for="name-lead" class="form-question-text">¿Cuáles son los principales desafíos o debilidades que enfrenta tu negocio en la actualidad?</label>-->
<!--										<div class="input-group"><span class="input-group-text"><i class="fas fa-file-alt"></i></span>-->
<!--											<input id="" type="text" class="form-control form-question-answer" placeholder="">-->
<!--										</div>-->
<!--									</div>-->
<!--								</div>-->
<!--								<div class="row align-items-end">-->
<!--									<p class="h4">Clientes</p>-->
<!--									<div class="col-12 col-md-6 mb-2 form-question-item">-->
<!--										<label for="name-lead" class="form-question-text">¿Conoces las preferencias y hábitos de consumo de tus clientes?</label>-->
<!--										<div class="input-group"><span class="input-group-text"><i class="fas fa-file-alt"></i></span>-->
<!--											<input id="" type="text" class="form-control form-question-answer" placeholder="">-->
<!--										</div>-->
<!--									</div>-->
<!--									<div class="col-12 col-md-6 mb-2 form-question-item">-->
<!--										<label for="name-lead" class="form-question-text">¿Cuál es tu calificación actual en Google o en otras plataformas de reseñas?</label>-->
<!--										<div class="input-group"><span class="input-group-text"><i class="fas fa-file-alt"></i></span>-->
<!--											<input id="" type="text" class="form-control form-question-answer" placeholder="">-->
<!--										</div>-->
<!--									</div>-->
<!--									<div class="col-12 col-md-6 mb-2 form-question-item">-->
<!--										<label for="name-lead" class="form-question-text">¿Ofreces un sistema de reservas online integrado en la web?</label>-->
<!--										<div class="input-group"><span class="input-group-text"><i class="fas fa-file-alt"></i></span>-->
<!--											<input id="" type="text" class="form-control form-question-answer" placeholder="">-->
<!--										</div>-->
<!--									</div>-->
<!--									<div class="col-12 col-md-6 mb-2 form-question-item">-->
<!--										<label for="name-lead" class="form-question-text">¿Qué estrategias utilizas para retener y fidelizar a tus clientes?</label>-->
<!--										<div class="input-group"><span class="input-group-text"><i class="fas fa-file-alt"></i></span>-->
<!--											<input id="" type="text" class="form-control form-question-answer" placeholder="">-->
<!--										</div>-->
<!--									</div>-->
<!--								</div>-->
<!--								<div class="row alig-items-end">-->
<!--									<div class="col-12 form-question-item">-->
<!--										<label for="name-lead" class="form-question-text">Comentarios</label>-->
<!--										<textarea class="form-control form-question-answer" placeholder="Caja de comentarios y mas" name="contactMessage" rows="4" cols="40"></textarea>-->
<!--									</div>-->
<!--								</div>-->
<!--							</div>-->
<!--						</div>-->
<!--					</div>-->
<!--				</div>-->
<!--			</section>-->
<!--			<div class="text-center">-->
<!--				<button id="submitFormButton" @click:prevent="submitForm" class="btn btn-secondary text-uppercase" style="width: 10rem">Enviar</button>-->
<!--			</div>-->
<!--		</div>-->
<!--	</section>-->

<!--</form>-->


<!-- Modal Content PRIVACY -->
<div class="modal fade" id="privacy-terms-modal" tabindex="-1" aria-labelledby="privacy-terms-modal-label"
	 aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h1 class="modal-title"><i
					class="fa icon-welkhome-club-pertenece align-items-center align-self-center justify-content-center"></i>Política
																															de Privacidad
				</h1>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="container">
					<div class="row">
						<div class="col-12">
							<p>
								La Política de Privacidad forma parte de las Condiciones Generales que rigen la presente
								Web
								www.wewelcom.io
								Última versión de enero 2024
							</p>
							<h4>¿Quién es el responsable del tratamiento del tratamiento de los datos?</h4>
							<p>
								Todos los datos facilitados por los Usuarios en los diferentes formularios, solicitudes
								de
								información, inscripción a Newsletter, para ponernos en contacto contigo o bien
								facilitarnos tu
								Curriculum que se encuentran en serán incorporados y tratados, tanto a través de medios
								telemáticos, informáticos y no automatizados, siendo el responsable de dichos
								tratamientos:
							</p>
							<ul class="style-ok">
								<li>Responsable: Verywel Tech S.L.</li>
								<li>Dirección: Calle Monte Esquinza 15, CP: 28010, Madrid</li>
								<li>CIF: B44990042</li>
							</ul>
							<p>
								Para cualquier duda o consulta, puedes ponerte en contacto con nosotros a través del
								siguiente E-mail: rgpd@wewelcom.io
							</p>
							<h4>
								¿Quién puede facilitarnos datos?
							</h4>
							<p>
								El acceso de los Usuarios a la página web puede implicar el tratamiento de datos de
								carácter
								personal. Al acceder a nuestra página, manifiestas que eres mayor de edad y que todos
								los
								datos que nos facilites, bien sean obligatorios para prestarte el servicio o información
								o bien
								datos adicionales que completan tu información y solicitud, son veraces y correctos.
								Estos
								datos nos los proporcionas voluntariamente.
								Para determinados tratamientos de datos te solicitaremos que nos otorgues tu
								consentimiento
								y para determinadas finalidades, te solicitaremos tu autorización a través de la
								activación de
								las casillas de validación específicas.
							</p>
							<h4>
								¿Qué datos recopilamos?
							</h4>
							<p>
								Al enviar una consulta de información, ponerte en contacto con nosotros a través de
								correo
								electrónico o por teléfono, que respondamos a tus solicitudes, nos envíes tu candidatura
								espontánea de empleo, contrates nuestros servicios, podemos solicitarle que nos
								facilites una
								serie de datos e información personal tal como tu nombre, apellidos, dirección, email,
								teléfono
								contacto, entre otros.
							</p>
							<h4>
								¿Cómo se usa la información y datos que nos facilitas?
							</h4>
							<p>
								Verywel Tech S.L. usará la información que nos proporcionas para las siguientes
								finalidades:
							</p>
							<h5>Tratamiento de datos de usuario para responder a tus solicitudes de información.</h5>
							<p>
								Trataremos tus datos para responder a tus solicitudes de información y ponernos en
								contacto
								contigo para remitirte información sobre nuestros productos y servicios, hacer un
								seguimiento
								de las mismas.

								La base que legitima este tratamiento es tu consentimiento.
								Tus datos serán conservados mientras se mantenga vigente el consentimiento; una vez
								producida tu baja voluntaria, serán bloqueados por un plazo de 2 años, siendo
								posteriormente
								eliminados.
							</p>
							<h5>
								Tratamiento de datos para contratación de servicios a través de la web www.wewelcom.io
							</h5>
							<p>
								Trataremos sus datos, con tu consentimiento expreso, para la tramitación y contratación
								de

								productos y servicios de Verywel Tech S.L., así como la prestación de nuestros
								servicios.
								La base que legitima el tratamiento es tu consentimiento; tus datos serán conservados,
								una
								vez finalizados los servicios contratados, por un plazo mínimo de 2 años;
								posteriormente,
								previo proceso de disociación, la información sobre servicios prestados será tratada a
								efectos
								estadísticos.
							</p>
							<h5>
								Tratamiento de datos para comunicaciones de productos y servicios de pertenecientes a la
								web www.wewelcom.io.
							</h5>
							<p>
								Trataremos sus datos, con tu consentimiento expreso, para remitirte comunicaciones de
								productos y servicios de pertenecientes a la web www.wewelcom.io.
								La base que legitima el tratamiento es tu consentimiento; en cualquier momento podrás
								darte
								de baja siguiendo las instrucciones que aparecen al final de todas las comunicaciones
								electrónicas o solicitándolo por escrito, como se describe en el apartado Derechos.
							</p>
							<h5>
								Tratamiento de datos CV de candidatos.
							</h5>
							<p>
								Trataremos tus datos, con tu consentimiento expreso, para finalidades de participación
								en
								procesos de selección de personal realizados por Verywel Tech S.L. El consentimiento es
								otorgado directamente por el candidato al remitir sus datos y Curriculum Vitae a la
								dirección:
								rgpd@wewelcom.io
								La base que legitima el tratamiento es tu consentimiento; en cualquier momento podrá
								revocar este consentimiento.
							</p>
							<h5>
								Contactos de la o del correo electrónico.
							</h5>
							<p>
								Trataremos los datos identificativos y de contacto que el usuario proporcione al ponerse
								en
								contacto con Verywel Tech S.L. mediante correo electrónico o telefónicamente, así como
								otros
								datos que el usuario incluya. Los datos serán tratados con las siguientes finalidad:
							</p>
							<ul class="style-ok">
								<li>Contestar consultas, solicitudes o peticiones.</li>
								<li>Darle de alta y registrarte como Usuario</li>
								<li>Gestionar el servicio solicitado, contestar solicitudes, o tramitar peticiones.</li>
								<li>Información por medios electrónicos, que versen sobre una solicitud.</li>
								<li>Realizar análisis y mejoras en la Web, sobre los servicios de Verywel Tech S.L.</li>
								<li>Información comercial por medios electrónicos, siempre que exista autorización
									expresa.
								</li>
							</ul>
							<p>
								Nuestro formulario de contacto cuenta con el símbolo * en los campos obligatorios. Si no
								se
								facilitan esos campos, no nos será posible contestar a la petición y por tanto, no se
								permitirá
								el envío del formulario.
								La base que legitima el tratamiento es tu consentimiento, que podrá ser revocado en
								cualquier
								momento.
							</p>
							<h5>
								Contactos de Redes Sociales.
							</h5>
							<p>
								Los datos personales disponibles en los perfiles de RRSS, así como aquellos que el
								usuario
								proporcione al Responsable al contactar con el mediante esta vía, serán tratados con la
								finalidad de:
							</p>
							<ul class="style-ok">
								<li>Contestar a consultas, solicitudes o peticiones.</li>
								<li>Gestionar el servicio solicitado, contestar a la solicitud, o tramitar una
									petición.
								</li>
								<li>Establecer una relación usuario-responsable, y crear una comunidad de seguidores.
								</li>
							</ul>
							<p>
								En este caso el tratamiento, se basa en la aceptación de una relación contractual en el
								entorno
								de la red social que corresponda y conforme a sus políticas de Privacidad, por lo que es
								recomendable que el usuario consulte las mismas.
								Verywel Tech S.L. únicamente podrá consultar o dar de baja los datos de forma
								restringida al
								tener un perfil específico. Estos serán tratados tanto tiempo como el usuario lo permita
								mediante las distintas interacciones que cada RRSS permita. Cualquier rectificación de
								los
								datos o restricción de información o de publicaciones, el usuario debe realizarla a
								través de la
								configuración de su perfil en la propia red social.
							</p>
							<h5>
								Tratamiento de datos de clientes y proveedores.
							</h5>
							<p>
								Trataremos los datos identificativos, de contacto y de facturación, así como todos los
								datos del
								cliente/proveedor estrictamente necesarios para el correcto desarrollo de la relación
								contractual, con las siguientes finalidades:
							</p>
							<ul class="style-ok">
								<li>Elaboración del presupuesto y seguimiento del mismo mediante comunicaciones entre
									las partes.
								</li>
								<li>Si realiza una orden de nuestros productos y servicios, o si proporciona servicios a
									Verywel Tech S.L., a nuestros empleados, clientes o socios como proveedor,
									procesaremos la información personal con el objetivo de completar las transacciones
									pertinentes, así como de administrarlas (por ejemplo, mediante el envío de facturas
									y
									la formalización de pagos o la entrega del producto o prestación del servicio), y
									gestionar correctamente el producto o servicio solicitado.
								</li>
								<li>Información por medios electrónicos, para el correcto desarrollo de la relación
									contractual o que puedan guardar relación con el producto/servicio objeto de la
									misma, en base al interés legítimo del responsable.
								</li>
								<li>Facturación y declaración de los impuestos oportunos. Los datos bancarios serán
									utilizados para la ejecución del servicio solicitado, como pueda ser el pago/cobro
									de
									un producto/servicio.
								</li>
								<li>Gestiones de control y recobro.</li>
							</ul>
							<p>
								La base legal es la ejecución o desarrollo de una relación contractual, o en su defecto
								el
								consentimiento al contactar con nosotros u ofrecernos productos. En caso de no
								proporcionar

								alguno de los datos necesarios para el correcto desarrollo del contrato, no podremos
								proceder
								a la prestación del servicio solicitado.
							</p>
							<h4>
								¿Incluimos datos personales de terceras personas?
							</h4>
							<p>
								No, como norma general sólo tratamos los datos que nos facilitan los titulares. Si se
								aportasen
								datos de terceros, se deberá con carácter previo, informar y solicitar su consentimiento
								a
								dichas personas, o de lo contrario Verywel Tech S.L. quedará exento de cualquier
								responsabilidad por el incumplimiento de este requisito.
							</p>
							<h4>
								¿Y datos de menores?
							</h4>
							<p>
								No tratamos datos de menores de 14 años. Por tanto, absténgase de facilitarlos si no
								tiene esa
								edad o, en su caso, de facilitar datos de terceros que no tengan la citada edad. Verywel
								Tech
								S.L. se exime de cualquier responsabilidad por el incumplimiento de esta previsión.
							</p>
							<h4>
								¿Cómo y dónde se comparte la información?
							</h4>
							<p>
								Salvo que el usuario autorice a la recepción de comunicaciones de productos y servicios
								por las
								Entidades que conforman el Grupo para que puedan remitirte información de los productos
								y
								servicios, Verywel Tech S.L. te informa, de manera expresa, que únicamente comunicará
								los
								datos a aquellos organismos y entes de la Administración Pública con competencia en la
								materia de acuerdo con la normativa legal vigente.
								Los datos personales podrán ser comunicados a encargados del tratamiento necesarios para
								la
								prestación del servicio, quienes tendrán firmado un contrato de prestación de servicios
								que les
								obliga a mantener el mismo nivel de privacidad que el del responsable.
							</p>
							<h4>
								Seguridad de tus datos
							</h4>
							<p>
								Verywel Tech S.L. da gran importancia a la seguridad de toda la información de
								identificación
								personal. Desafortunadamente, la transmisión de información a través de Internet no es
								completamente segura; por ello, hacemos esfuerzos y destinamos recursos a mejorar cada
								día
								nuestra página y que las funcionalidades que ponemos a tu disposición sean más seguras.
								Una vez que hayamos recibido tu información, sobre dichos datos hemos implementado
								medidas de seguridad para garantizar el acceso, confidencialidad, integridad y evitar
								cualquier
								pérdida, mal uso y/o alteración de los datos que están bajo nuestro control. Por
								ejemplo,
								nuestras políticas de seguridad y privacidad son revisadas periódicamente y mejoradas
								según
								sea necesario y sólo el personal autorizado tiene acceso a la información del usuario.
							</p>
							<h4>
								Datos de navegación
							</h4>
							<p>
								Verywel Tech S.L. solamente registrará datos anónimos con respecto a la navegación de
								los
								usuarios, con el fin de obtener datos estadísticos sobre fecha y hora de acceso al Web
								Site,
								navegación, visitas, uso de funcionalidades, descargas, visualizaciones. Además, con el
								fin de
								conocer la eficacia de los banners y links que apuntan a nuestro sitio, identificaremos
								también
								el link de procedencia de la visita. Toda la información obtenida es completamente
								anónima, y
								en ningún caso podría ser utilizada para identificar a un usuario concreto ni es posible
								asociarla
								con una persona en particular, simplemente nos servirá para mejorar el servicio que
								ofrecemos a nuestros usuarios.

								Te informamos, asimismo, que durante la navegación y visita a las páginas de Verywel
								Tech S.L.
								podemos utilizar balizas de seguimiento y cookies, que nos permiten adecuar nuestros
								servicios y productos a las preferencias e intereses de los usuarios y visitantes. En
								caso de
								visitantes de la página, los datos que nos proporcionan estas balizas de seguimiento no
								pueden
								ser asociados a una persona concreta ni nos permiten obtener una identificación directa
								del
								usuario.
							</p>
							<h4>
								Actualización en la contratación de servicio prestado
							</h4>
							<p>
								Para aquellas situaciones donde Verywel Tech S.L. deba prestar al Cliente unos servicios
								de
								naturaleza y características distintas a los propios de Verywel Tech S.L. es posible que
								requieran la adaptación de la relación entre ambas partes a efectos de privacidad. En
								este
								sentido, Verywel Tech S.L. y el Cliente acordarán la preparación de un acuerdo en que
								queden
								reguladas las correspondientes obligaciones, responsabilidades y derechos aplicables a
								cada
								una de las partes en materia de Protección de Datos de Carácter Personal.
							</p>
							<h4>
								Acuerdo de confidencialidad
							</h4>
							<p>
								Verywel Tech S.L. y el Cliente se comprometen a mantener reservada y confidencial la
								existencia y el contenido de toda la documentación e información que se facilite,
								transmita o
								divulgue con independencia del método, forma o soporte utilizado (en adelante, &quot;Información
								Confidencial&quot;), comprometiéndose a no realizar ninguna divulgación a terceros o
								comunicación pública sin la previa autorización por escrito de la otra parte.
								De forma enunciativa pero no limitativa, se entenderá como Información Confidencial la
								información referida a datos de Clientes, su existencia, su estructura, planes de
								promoción y
								venta, códigos fuente y objeto de programas informáticos, sistemas, técnicas, Propiedad
								Industrial e Intelectual, datos técnicos y no técnicos, dibujos, bocetos, datos
								financieros,
								planes relativos a nuevos productos, datos relativos a clientes o potenciales Clientes
								así como
								cualquier otra información utilizada en el ámbito empresarial de Verywel Tech S.L.
								La obligación de confidencialidad subsistirá incluso después de la resolución, por
								cualquier
								causa, de la relación contractual entre las partes.
								El incumplimiento de la obligación de confidencialidad asumida en estos Términos y
								Condiciones o la devolución de la Información Confidencial establecida anteriormente
								dará
								derecho a cualquiera de las partes a reclamar los daños y perjuicios que dicho
								incumplimiento
								hubiera generado según los límites establecidos en la normativa vigente.
								La obligación de confidencialidad no será de aplicación en los casos en los que:
								Después de haber sido facilitada como Información Confidencial, deviniese accesible
								públicamente, sin que en dicha circunstancia hubiese intervenido incumplimiento alguno
								de la
								presente cláusula; o
								Se encontrare legalmente en posesión de la parte receptora en el momento en que hubiese
								sido facilitada por la parte emisora, o que hubiese sido obtenida por aquella de manera
								independiente y anterior a haberle sido facilitada por esta; o
								Que la parte receptora demuestre haberla obtenido legalmente de modo no restringido de
								cualquier tercero que no estuviese sujeto por obligaciones de confidencialidad similares
								con la
								parte emisora; o

								Que deba ser obligatoriamente facilitada en virtud de disposición legal o por resolución
								válidamente emitida por cualquier autoridad administrativa competente, tribunal u órgano
								jurisdiccional, legalmente facultado para obligar a tal disponibilidad, siempre y cuando
								la parte
								receptora así requerida notifique inmediatamente a la parte emisora la recepción de tal
								requerimiento, a fin de que la parte emisora pueda evaluar si existe posibilidad de
								eludir el
								mismo o pueda prestar cualquier apoyo razonablemente solicitado por la parte receptora.
							</p>
							<h4>
								Tus Derechos y el acceso a la información
							</h4>
							<p>
								De acuerdo con la normativa vigente, Reglamento UE 679/2016, de 27 de abril, General de
								Protección de Datos y a con la Ley Orgánica 3/2018, de Protección de Datos Personales y
								Garantía de los Derechos Digitales tienes la posibilidad de ejercitar los siguientes
								derechos
								personalísimos, acreditando de manera fehaciente tu identidad, bien a través de medios
								electrónicos (en el caso de Newsletter, comunicaciones y correos electrónicos) o bien a
								través
								de comunicación por escrito dirigida a nuestro Responsable de Privacidad. Tus derechos
								son
								los siguientes:
							</p>
							<ul class="style-ok">
								<li>Derecho a revocar tu consentimiento y solicitarnos que no procesemos tus datos.</li>
								<li>Derecho de acceso a la información que disponemos referente a tu persona, con
									especificación de las finalidades del tratamiento que realizamos y las
									comunicaciones
									que hemos realizado a terceras administraciones y empresas, bien por encontrarse
									autorizadas por ley o por ser necesarias para la prestación del servicio que nos has
									solicitado, bien por haber otorgado tu consentimiento.
								</li>
								<li>Derecho de rectificación de tus datos, por el que actualizaremos y pondremos al día
									la
									información y datos que disponemos según la información que nos facilites.
								</li>
								<li>Derecho a la supresión de tus datos, una cumplidos los plazos legales de
									conservación
									fijados por la normativa de aplicación.
								</li>
								<li>Derecho a oponerte a que realicemos cualquier actividad de tratamiento de tus datos,
									a través de la revocación de tu autorización/consentimiento.
								</li>
								<li>Derecho a limitar el tratamiento de tus datos, como en caso de que interpongas una
									reclamación o quieras que conservemos tus datos por un plazo superior, evitando su
									supresión.
								</li>
								<li>Derecho a la portabilidad de tus datos, por el que te facilitaremos en un formato de
									archivo de lectura común aquellos datos que nos has facilitado en el momento de tu
									registro como usuario.
								</li>
							</ul>
							<p>
								Además, te informamos que siempre podrás acudir a la Autoridad de Control Española de
								Protección de Datos (Agencia Española de Protección de Datos, www.agpd.es) para
								solicitar su
								tutela o interponer una reclamación en relación al tratamiento de datos personales.
							</p>
							<h4>
								Responsable de Privacidad
							</h4>
							<p>
								En cualquier momento nos podrás solicitar, de manera totalmente gratuita, bien por carta
								o a
								través de correo electrónico, el ejercicio de sus derechos. Tus solicitudes deberás
								enviarlas a
								nuestro Responsable de Privacidad en la siguiente dirección:
							</p>
							<ul class="style-ok">
								<li>Responsable: Verywel Tech S.L.</li>
								<li>Dirección: Calle Monte Esquinza 15, CP: 28010, Madrid</li>
								<li>CIF: B44990042</li>
								<li>Email: rgpd@wewelcom.io</li>
							</ul>
							<h4>
								Cambios en la Política de Privacidad
							</h4>
							<p>

								De vez en cuando podremos modificar o adaptar nuestra Política de Privacidad y
								Tratamiento
								de datos de Carácter Personal. Las modificaciones serán comunicadas a nuestros usuarios.
							</p>

						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer text-end">
				<button type="button" class="btn btn-border btn-outline-primary " data-bs-dismiss="modal">Salir</button>
			</div>
		</div>
	</div>
</div>
<!-- END Modal Content PRIVACY -->

<FooterWewelcom/>
